.nopad {
  padding: 0 !important;
}

.order-road {
  background-color: #4d4773;
  color: #fff !important;
}

#offsidebar-btn,
#offsidebar-btn-right {
  height: 40px;
  width: 40px;
  background-color: #4cb037;
  color: #fff;
  z-index: 10;
  font-size: 16px;
  text-align: center;
  top: 45px;
  line-height: 40px;
  cursor: pointer;
  position: absolute;
}

#offsidebar-btn {
  left: 260px;
  border-radius: 0 3px 3px 0;
}

#offsidebar-btn-right {
  right: 260px;
  border-radius: 3px 0 0 3px;
}

.log-label {
  display: block;
  padding: 0px;
}

span.order-type {
  display: inline-block;
  font-size: 12px;
  padding: 3px;
  border-radius: 2px;
  color: #fff;
  background-color: #0382c5;
  margin-right: 5px;
  vertical-align: top;
  text-align: center;
  line-height: 16px;
}

.order-warning {
  background-color: #f1703f;
  color: #fff !important;
}

.select2-drop {
  z-index: 99999 !important;
}

.modal-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-left-radius: 3px;
}

.logo-middle {
  position: absolute;
  left: 50% !important;
  margin-left: -20px !important;
  top: 3px;
}

#sidebar_right {
  background-color: #f9f9f9 !important;
}

.sidebar-head {
  position: fixed;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14);
  height: 60px;
  display: block;
  z-index: 5;
}

.sidebar-block {
  overflow: auto !important;
  position: relative;
  top: 140px;
  height: calc(100% - 290px) !important;
}

.sidebar-header {
  font-size: 15px;
  padding: 6px 22px 20px 22px;
  width: 100%;
  background-color: #2bbfc9;
  color: #fff;
  height: 50px;
}

ul.sidebar-menu-tab {
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14);
  height: 51px;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}

ul.sidebar-menu-tab li {
  float: left;
  text-align: center;
  list-style-type: none;
  width: 33.33%;
  padding: 5px 0;
  cursor: pointer;
  background-color: #fff !important;
  border-bottom: 3px solid #eee;
}

ul.sidebar-menu-tab li.br1 {
  border-bottom: 1px solid #ddd;
}

ul.sidebar-menu-tab li.w100 {
  width: 100% !important;
}

ul.sidebar-menu-tab li a:hover,
ul.sidebar-menu-tab li a:focus,
ul.sidebar-menu-tab li a:visited,
ul.sidebar-menu-tab li a:active {
  text-decoration: none;
}

ul.sidebar-menu-tab li.active {
  color: #f1703f !important;
  border-bottom: 3px solid #f1703f;
}

ul.sidebar-menu-tab li.active a,
ul.sidebar-menu-tab li.active h5 {
  color: #f1703f !important;
}

.list-sidebar {
  padding: 10px 12px 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  margin-top: 2px;
  cursor: pointer;
  width: 100%;
}

.status-area {
  display: inline-block;
  width: 24px;
  margin-top: 14px;
  vertical-align: top;
}

.desc-area {
  display: inline-block;
  width: calc(100% - 30px);
}

.panel-full {
  position: fixed;
  top: 143px;
  right: 29px;
  bottom: 50px;
  left: 31px;
  text-align: center;
  background-color: #fff;
}

.order-success {
  background-color: #c3d62d;
  color: #333 !important;
}

.order-danger {
  background-color: #f5393d;
  color: #fff !important;
}

.order-primary {
  background-color: #4eccdb;
  color: #fff !important;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

gm-map {
  display: block;
  width: 100%;
  height: 300px;
}

div#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: rgba(35, 47, 61, 0.8);
}

div#preloader .middle {
  position: relative;
  top: 50%;
  margin-top: -90px;
  text-align: center;
}

div#preloader .middle img {
  width: 160px;
}

div#preloader .middle .loading-text {
  color: #fff;
  text-align: center;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loading-text span {
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loading-text span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-text span:nth-child(3) {
  animation-delay: 0.4s;
}

.navbar-logo-text img {
  width: 50px !important;
  text-align: center;
  margin: 10px auto;
  padding-left: 6px;
}

.tl-sm {
  width: 15%;
  padding-left: 10px;
  font-size: 24px;
  margin-top: -4px;
  margin-left: -120px;
  z-index: 99;
  position: relative;
}

.star-rating {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.star-rating .star {
  padding: 1px;
  color: #ddd;
  font-size: 20px;
  text-shadow: 0.05em 0.05em #aaa;
  list-style-type: none;
  display: inline-block;
  cursor: pointer;
}

.star-rating .star.filled {
  color: #fd0;
}

.star-rating.readonly .star.filled {
  color: #666;
}

#card-container {
  display: block;
  margin: 50px;
}

.black-text,
.black-text .panel-title,
.black-text .help-block {
  color: #000 !important;
}

.highlight {
  color: red !important;
}

.loading {
  background: rgba(244, 247, 249, 0.4) !important;
  z-index: 9;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#orders {
  overflow: auto;
}

.select2-container {
  width: 100%;
}

.select2-container .select2-choice {
  height: 32px !important;
  line-height: 30px !important;
}

#map-order {
  width: 100%;
  height: 400px;
}

#map-history {
  width: 100%;
  height: 300px;
  border: 1px solid #ddd;
}

.pac-container {
  z-index: 9999 !important;
}

#map {
  height: calc(100% - 189px);
  position: absolute;
  top: 153px;
  bottom: 0;
  left: 260px;
  right: 260px;
  z-index: 0;
}

#customer_map {
  height: calc(100% - 116px);
  position: absolute;
  top: 116px;
  bottom: 0;
  z-index: 0;
  left: 0;
}

.clickable-row {
  cursor: pointer;
}

.sidebar {
  background-color: #282828;
}

@media (min-width: 992px) {
  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1037;
    display: block;
    overflow: auto;
    background-color: #282828;
  }
}

@media (max-width: 768px) {
  #sidebar_right {
    top: 40px !important;
    width: 100% !important;
  }

  .tab {
    display: block !important;
  }

  .logo-middle {
    display: none !important;
  }

  .show-mobile-sidebar {
    display: block !important;
  }

  .hide-mobile-sidebar {
    display: none !important;
  }

  #map {
    display: block !important;
  }

  #offsidebar-btn,
  #offsidebar-btn-right {
    display: none !important;
  }
}

.tab {
  display: none;
}

.tab,
.tab ul {
  width: 100% !important;
  position: fixed;
  background: #fff;
  z-index: 99;
  margin-top: -1px;
  padding: 0;
}

.tab ul li {
  float: left;
  width: 33.33% !important;
  padding: 10px;
  border-right: 1px solid #eee;
  background: #fff;
  list-style-type: none;
  text-align: center;
  font-weight: 600;
}

.tab ul li.active {
  background: #aaa;
  color: #fff;
}

.tab ul li.active a {
  color: #fff;
  text-decoration: none;
}

.tab ul li a:hover {
  text-decoration: none;
}

.list-group.list-group-root {
  padding: 0;
  overflow: hidden;
}

.list-group.list-group-root .list-group {
  margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}

.list-group.list-group-root > .list-group-item:first-child {
  border-top-width: 0;
}

.list-group.list-group-root > .list-group > .list-group-item {
  padding-left: 30px;
}

.list-group.list-group-root > .list-group > .list-group > .list-group-item {
  padding-left: 45px;
}

.text-assign {
  color: #ff5722;
  font-weight: 600;
}

.text-not_assign {
  color: #ffbc0b;
  font-weight: 600;
}

.text-acknowledged {
  color: #cc50c8;
  font-weight: 600;
}

.text-started {
  color: #0190f6;
  font-weight: 600;
}

.text-in_progress {
  color: #6e7bc7;
  font-weight: 600;
}

.text-successful {
  color: #83d238;
  font-weight: 600;
}

.text-cancel {
  color: #f44336;
  font-weight: 600;
}

#map div.gmnoprint[title="location"] {
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  top: 0;
  display: block;
  margin-left: -8px;
  margin-top: -9px;
  background: rgba(76, 176, 55, 0.4);
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2.5s ease-out infinite;
}

#map div.gmnoprint[title="location"] img {
  display: none;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (device-width: 768px) {
  #map div.gmnoprint[title="location"] {
    margin: -1px 0 0 0px;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.payment-success-icon {
  width: 80px;
  height: 80px;
  padding-top: 15px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  background-color: #e5eaee;
}

.left-day-unless {
  background: #002b40;
}

.left-day-unless a {
  color: #f4c432 !important;
  text-transform: uppercase;
}

.tour-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

input[type="checkbox"].ng-valid-parse {
  border: 1px solid red !important;
}

body.sb-top.sb-top-sm #sidebar_left {
  position: fixed;
}

.settings-sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 45px;
  z-index: 999;
  width: 200px;
  background: #2a3644;
  overflow: scroll;
}

.settings-sidebar li {
  margin: 0;
  width: 100%;
}

.settings-sidebar li a,
.settings-sidebar li a span {
  text-transform: capitalize !important;
  color: #ccc;
}

.settings-sidebar li:hover > a,
.settings-sidebar li:hover > a span {
  color: #fff;
}

.settings-sidebar li.active {
  background: #f4f7f9;
}

.settings-sidebar li.active > a,
.settings-sidebar li.active > a span {
  color: #111 !important;
}

.event-list {
  list-style: none;
  font-family: "Roboto", sans-serif;
  margin: 0px;
  padding: 0px;
}

.event-list > li {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 5px rgb(51, 51, 51);
  box-shadow: 0px 0px 5px rgba(51, 51, 51, 0.7);
  padding: 0px;
  margin: 0px 0px 20px;
}

.event-list > li > span {
  display: inline-block;
  width: 100%;
  color: rgb(255, 255, 255);
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
}

.event-list > li > span > span {
  display: none;
}

.event-list > li > span > .day {
  display: block;
  font-size: 68pt;
  font-weight: 100;
  line-height: 1;
}

.event-list > li span > .month {
  display: block;
  font-size: 24pt;
  font-weight: 900;
  line-height: 1;
}

.event-list > li > img {
  width: 100%;
}

.event-list > li > .info {
  padding-top: 5px;
  text-align: center;
}

.event-list > li > .info > .title {
  font-size: 17pt;
  font-weight: 700;
  margin: 0px;
}

.event-list > li > .info > .desc {
  font-size: 13pt;
  font-weight: 300;
  margin: 0px;
}

.event-list > li > .info > ul,
.event-list > li > .social > ul {
  display: table;
  list-style: none;
  margin: 10px 0px 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
}

.event-list > li > .social > ul {
  margin: 0px;
}

.event-list > li > .info > ul > li,
.event-list > li > .social > ul > li {
  display: table-cell;
  cursor: pointer;
  color: rgb(30, 30, 30);
  font-size: 11pt;
  font-weight: 300;
  padding: 3px 0px;
}

.event-list > li > .info > ul > li > a {
  display: block;
  width: 100%;
  color: rgb(30, 30, 30);
  text-decoration: none;
}

.event-list > li > .social > ul > li {
  padding: 0px;
}

.event-list > li > .social > ul > li > a {
  padding: 3px 0px;
}

.event-list > li > .info > ul > li:hover,
.event-list > li > .social > ul > li:hover {
  color: rgb(30, 30, 30);
  background-color: rgb(200, 200, 200);
}

.event-list > li > .social > ul > li.plugin:hover {
  color: rgb(30, 30, 30);
  background-color: #f5f5f5 !important;
}

.edit a,
.delete a {
  display: block;
  width: 100%;
  color: #f3c331 !important;
}

.delete a {
  color: #f31519 !important;
}

.sort a {
  color: #333 !important;
}

.edit:hover a {
  color: rgb(255, 255, 255) !important;
  background-color: #f3c331 !important;
}

.delete:hover a {
  color: rgb(255, 255, 255) !important;
  background-color: #f31519 !important;
}

.sort:hover a {
  color: rgb(255, 255, 255) !important;
  background-color: #333 !important;
}

@media (min-width: 768px) {
  .event-list > li {
    position: relative;
    display: block;
    width: 100%;
    height: 90px;
    padding: 0px;
  }

  .event-list > li > span,
  .event-list > li > img {
    display: inline-block;
  }

  .event-list > li > span,
  .event-list > li > img {
    width: 90px;
    float: left;
  }

  .event-list > li > .info {
    background-color: rgb(245, 245, 245);
    overflow: hidden;
  }

  .event-list > li > span,
  .event-list > li > img {
    width: 90px;
    height: 90px;
    padding: 0px;
    margin: 0px;
  }

  .event-list > li > .info {
    position: relative;
    height: 90px;
    text-align: left;
    padding-right: 40px;
  }

  .event-list > li > .info > .title,
  .event-list > li > .info > .desc {
    padding: 0px 10px;
  }

  .event-list > li > .info > ul {
    position: absolute;
    left: 0px;
    bottom: 0px;
  }

  .event-list > li > .social {
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    width: 40px;
  }

  .event-list > li > .social > ul {
    border-left: 1px solid rgb(230, 230, 230);
  }

  .event-list > li > .social > ul > li {
    display: block;
    padding: 0px;
  }

  .event-list > li > .social > ul > li > a {
    display: block;
    width: 40px;
    padding: 10px 0px 9px;
  }
}

ul.worker-lists {
  display: inline;
}

ul.worker-lists li {
  float: left;
  list-style-type: none !important;
  margin-right: 10px;
}

.glb-ldr-prt {
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.gl-ldr-cld {
  vertical-align: middle;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
}

.gl-ldr-ctr {
  background-color: #f4f7f9;
  margin: 0 auto;
  border-radius: 6px;
}

#global_loader .gl-ldr-ctr {
  width: 80px;
  height: 80px;
  padding: 15px;
}

.gl-loader {
  width: 50px;
  height: 50px;
  position: relative;
  border-top: 5px solid rgba(0, 0, 0, 0.03);
  border-right: 5px solid rgba(0, 0, 0, 0.03);
  border-bottom: 5px solid #2a3644;
  border-left: 5px solid #2a3644;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loading 1.1s infinite linear;
  animation: loading 1.1s infinite linear;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.gl-loader,
.gl-loader:after {
  border-radius: 50%;
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}

.profile-usertitle-name {
  color: #5a7391;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}

.selected-work {
  background: #eaeaea !important;
}

.iw-200 {
  width: 200px;
  display: inline-block;
}

.bg-yellow {
  background-color: #f0c808 !important;
  color: #fff !important;
}

.bg-blue {
  background-color: #5dc1ea !important;
  color: #fff !important;
}

.bg-red {
  background-color: #d1462f !important;
  color: #fff !important;
}

.bg-green {
  background-color: #82d173 !important;
  color: #fff !important;
}

.bg-purple {
  background-color: #663399 !important;
  color: #fff !important;
}

.bg-gray {
  background-color: #7c7a7a !important;
  color: #fff !important;
}

.bg-pink {
  background-color: #ff69b4 !important;
  color: #fff !important;
}

.bg-orange {
  background-color: #ff8c42 !important;
  color: #fff !important;
}

.bg-brown {
  background-color: #8a4412 !important;
  color: #fff !important;
}

.border-chart {
  border: 1px solid #ddd;
}

#content .panel.date-range {
  padding: 0px;
  margin-bottom: 0px;
  border: none;
}

#content .panel.date-range .panel-body {
  margin: 0px;
}

#content .panel.date-range .panel-body label {
  font-size: 16px;
  font-weight: bold;
  color: #245171;
}

.dropdown-menu .item {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu .item:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

@media (max-width: 767px) {
  .sidebar-left-content.settings-sidebar {
    display: none !important;
  }

  .chute.chute-center .center-block {
    margin-left: 0px !important;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  #print-content * {
    visibility: visible;
    overflow: visible;
  }

  #mainPage * {
    display: none;
  }

  .modal {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    min-height: 550px;
    visibility: visible;
    /**Remove scrollbar for printing.**/
    overflow: visible !important;
  }

  .modal-dialog {
    visibility: visible !important;
    /**Remove scrollbar for printing.**/
    overflow: visible !important;
  }

  li {
    page-break-after: auto;
  }

  .hide-print {
    display: none !important;
  }

  .order-id {
    display: block !important;
  }

  .print-modal-customer {
    padding-left: 12px !important;
  }
}

.tutorial-number {
  width: 30px;
  height: 30px;
  background: #c3d62d;
  border-radius: 30px;
  color: #fff;
  display: -webkit-inline-box;
  padding: 6px 11px;
  margin-right: 10px;
  margin-top: 10px;
}

#map-canvas img {
  max-width: none !important;
}

#iw-container {
  min-width: 200px;
}

#iw-container .iw-content {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  margin-right: 1px;
  padding: 2px;
  overflow-y: auto;
}

.iw-content p {
  margin: 0px !important;
  padding: 4px 0px !important;
}

#iw-container a.btn.btn-xs {
  padding: 6px 8px;
}

.iw-content img {
  float: right;
  margin: 0 5px 5px 10px;
}

.iw-subTitle {
  font-size: 16px;
  font-weight: 700;
  padding: 5px 0;
}

#sideMenu li a > span {
  width: 16px;
  height: 16px;
  display: inline-block;
}

#sideMenu .setting-menu .navbar-header,
#sideMenu li a {
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.8px;
}

#sideMenu li a {
  color: #939393;
  transition: all 0.5s;
  height: 60px;
  padding: 14px 18px;
  white-space: nowrap;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

#sideMenu li a i {
  font-size: 18px;
}

#sideMenu .setting-menu li a {
  height: 58px;
  line-height: 2;
}

#sideMenu .setting-menu > li > a {
  white-space: inherit;
  height: auto;
}

#sideMenu .setting-menu .navbar-header {
  padding: 22px 20px;
  margin: 0;
  line-height: normal;
  color: #fff;
  width: 100%;
  border-bottom: 1px solid rgba(99, 99, 99, 0.47);
  height: 60px !important;
}

#sideMenu .setting-menu .parent-menu > ul {
  padding-left: 20px;
  background: #303030;
}

#sideMenu li a:hover {
  color: #fff;
}

.new-order-info {
  position: absolute;
  right: 0;
  padding: 10px 10px;
}

.select-worker-box {
  color: rgba(0, 0, 0, 0.21);
  cursor: pointer;
  height: 50px;
  line-height: 33px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  text-align: center;
  position: relative;
}

.top-board {
  display: block;
  position: relative;
  padding: 5px 16px;
}

.board {
  overflow-y: auto;
  overflow-x: none;
  white-space: nowrap;
  width: calc(100% - 12px);
  margin-top: 10px !important;
  margin-left: 10px;
  position: fixed;
  height: calc(100% - 122px);
}

.list-wrapper {
  width: 400px;
  margin: 0 5px;
  max-height: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}

.list-cards {
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 6px;
  max-width: 400px;
  max-height: calc(100vh - 250px) !important;
  overflow: auto;
  position: relative;
  z-index: 0;
}

.list-wrapper .panel {
  height: calc(100% - 20px) !important;
  background: #e2e4e6;
}

.list {
  background: #e2e4e6;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  white-space: normal;
  float: none;
}

.list-card {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 6px;
  max-width: 400px;
  min-height: 20px;
  position: relative;
  z-index: 0;
}

.list-card-details {
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.list-wrapper .panel-heading {
  border-radius: 3px;
  background: #056496;
  color: #fff !important;
}

.task-badge {
  background: #f3f7fa;
  color: #1d364b;
  border-radius: 2px;
  font-weight: 500;
  padding: 10px 5px;
  border: 1px solid #e2e4e6;
}

.low-border {
  border-left: 5px solid #c3d62d;
}

.middle-border {
  border-left: 5px solid #f1703f;
}

.high-border {
  border-left: 5px solid #f5393d;
}

.list-wrapper .panel-heading strong,
.list-wrapper .panel-heading a {
  color: #f4f7f9 !important;
}

.chat-window > div > .panel {
  border-radius: 5px 5px 0 0;
}

.icon_minim {
  padding: 2px 10px;
}

.msg_container_base {
  background: #fff;
  margin: 0;
  min-height: 300px;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.top-bar {
  background: #666;
  color: white;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.msg_receive {
  padding-left: 0;
  margin-left: 0;
}

.msg_sent {
  padding-bottom: 20px !important;
  margin-right: 0;
}

.msg_container {
  overflow: hidden;
  display: flex;
  margin-bottom: -10px;
  background: #fff;
  max-height: 500px;
  margin-top: 25px;
}

.msg_container:first-child {
  margin-top: 0;
}

.base_receive > .avatar:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 5px solid #fff;
  border-left-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

.base_sent {
  justify-content: flex-end;
  align-items: flex-end;
}

.base_sent > .avatar:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid white;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 1px 1px 2px rgba(black, 0.2);
}

.msg_sent > time {
  float: right;
}

.msg_container_base::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.msg_container_base::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.work-steps {
  border: 1px solid #b3b3b3;
  background: #fff;
  margin: 10px 0;
  border-radius: 5px;
  padding: 12px;
}

.main-left-menu {
  width: 50px !important;
  z-index: 999;
  left: 0;
  background: #f9f9f9;
  border-right: 1px solid #dfdfdf;
}

.main-left-menu ul {
  margin: 0;
  padding: 0;
}

.main-left-menu ul li {
  list-style-type: none !important;
  border-bottom: 1px solid #dfdfdf;
}

.main-left-menu ul li.active {
  background: #0382c5 !important;
}

.main-left-menu ul li.active > a {
  color: #fff;
}

.main-left-menu ul li:hover {
  background: #0382c5 !important;
}

.main-left-menu ul li:hover > a {
  color: #fff;
}

.main-left-menu ul li a {
  font-size: 30px;
  padding: 11px;
}

.edit-project {
  display: none;
  position: absolute;
  background: rgba(0, 0, 1, 0.5);
  height: 30px;
  width: 30px;
  border-radius: 4px;
  line-height: 2;
  color: #fff;
  top: 10px;
  right: 10px;
}

.edit-project:hover {
  color: #fff;
}

.project-list {
  float: left;
  width: calc(25% - 20px);
}

@media (max-width: 768px) {
  .project-list {
    width: calc(100% - 20px);
  }
}

.project-list:hover > .edit-project {
  display: block;
}

.project-list a:hover {
  text-decoration: none !important;
}

.card-header {
  padding: 10px;
  color: #fff !important;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-header a {
  color: #fff !important;
}

.card-header:hover > .edit-task-step {
  display: block;
}

.edit-task-step {
  display: none;
}

.menu-panel {
  background-color: #edeff0;
  -webkit-transition-property: -webkit-transform, width;
  transition-property: transform, width;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  width: 340px !important;
  z-index: 5;
  right: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.addon {
  background: #fff;
  border: 1px solid #c0cae3;
  margin-bottom: 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  height: calc(100% - 110px);
}

.addon li {
  padding: 10px;
  border-top: 1px solid #eee;
  overflow: hidden;
}

.addon li {
  list-style: none;
}

.clearfix {
  display: block;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  overflow: hidden;
}

.addon li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.addon ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
  height: calc(100% - 37px);
  overflow: auto;
}

.addon .round {
  border-radius: 100%;
  display: block;
  height: 48px;
  width: 49px;
  float: left;
}

.addon li em {
}

.addon li em.extra {
  background-position: -118px 0;
}

.addon li em.hot {
  background-position: -58px 0;
}

.addon p {
  padding: 10px 15px;
  margin: 0;
}

p {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

.addon li .legend-info {
  float: left;
  margin-left: 10px;
  width: 255px;
}

.addon strong {
  display: block;
  margin: 0 0 4px;
}

strong {
  font-weight: bold;
}

.edit-task-step a:hover {
  text-decoration: none;
}

.scroll-task {
  max-height: 400px;
  overflow: auto;
}

.subnavbar.navbar-left,
.subnavbar.navbar-right {
  float: left;
  position: relative;
  height: 30px;
  max-height: 30px;
}

.subnavbar .dropdown-toggle {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 3px !important;
}

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.btn-opacity,
.btn-opacity:hover,
.btn-opacity:active,
.btn-opacity:focus {
  max-height: 30px;
  line-height: 30px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 3px !important;
}

.search-input {
  text-align: left;
  width: 100%;
}

.dropdown-menu-form label {
  color: #5f6c74 !important;
}

.form-horizontal .control-label {
  text-align: left;
}

.operation-live-search ul.select2-choices {
  background: rgba(255, 255, 255, 0.1) !important;
}

.operation-live-search .select2-choices .select2-search-field input {
  background: rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
}

.operation-live-search .select2-choices {
  border-radius: 3px !important;
  border: none !important;
  box-shadow: none !important;
}

.operation-live-search .ui-select-refreshing.glyphicon {
  padding: 9px 14px !important;
}

.operation-live-search
  .select2-choices
  .select2-search-field
  input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff !important;
  text-transform: capital;
  text-align: center;
}

.operation-live-search
  .select2-choices
  .select2-search-field
  input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff !important;
  text-transform: capital;
  text-align: center;
}

.operation-live-search
  .select2-choices
  .select2-search-field
  input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff !important;
  text-transform: capital;
  text-align: center;
}

.operation-live-search
  .select2-choices
  .select2-search-field
  input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff !important;
  text-transform: capital;
  text-align: center;
}

.bootstrap-select.btn-group .dropdown-toggle .caret,
.operation-live-search .select2-choices .select2-search-field input {
  border: solid #828c95;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -6px;
}

.form-group label.with-caret {
  border: solid #828c95;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  top: 18px;
  right: 12px;
}

.form-group.relative {
  position: relative;
}

.color-palette {
  padding: 0;
}

.color-palette li {
  float: left;
}

li.color-selected {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  list-style-type: none;
}

ul.color-palette li.selected:after {
  content: "\f17b";
  font-family: "FontAwesome";
  display: inline-block;
  font-size: 15px;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 30px;
}

.right .modal-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.y-scroll {
  display: flex;
  overflow-x: scroll !important;
  width: 75%;
  max-width: 100%;
}

.detail-box {
  background: #fff;
  border-radius: 4px;
  border: 2px solid #e4e7e9;
  padding: 10px;
  margin-bottom: 10px;
}

.with-tab {
  padding: 0px;
}

.text-input {
  cursor: auto;
  text-align: left;
}

.steps section.step {
  padding: 50px;
}

.tutorial h2 {
  color: #36495e;
  font-size: 30px;
  font-weight: 400;
}

.tutorial p {
  color: #5f6c74;
  font-size: 16px;
}

p.ttContent {
  font-size: 14px;
  color: #5f6c74;
}

.tour-tip p {
  color: #5f6c74;
  font-size: 14px;
}

.tour-tip .tour-content-wrapper .button {
  background: #469aa7;
}

.tour-tip .tour-content-wrapper .button:hover,
.tour-tip .tour-content-wrapper .button:focus,
.tour-tip .tour-content-wrapper .button.active {
  background: #6ea9ad;
}

.steps-indicator li.done a:before {
  background-color: #469aa7;
}

.order-list-time {
  color: #000000;
  font-weight: bolder;
  font-size: 18px;
}

.order-list-address {
  font-size: 16px;
  color: #5f6c74;
}

.order-list-worker {
  font-size: 13px;
  color: #5f6c74;
}

.order-list-status {
  color: #fff;
  padding: 1px 5px;
  border-radius: 3px;
  font-weight: 400;
}

.sidebar-menu-tab li a h5 {
  color: #464646;
}
.sidebar-menu-tab li a small {
  color: #464646;
  font-size: 16px;
}

.pagination {
  margin: 0 auto !important;
  display: table !important;
  text-align: center !important;
}

.order-detail-page .panel-body {
  background: #fff !important;
}

.daterangepicker .ranges li {
  color: #5f6c74;
}

.plugin-page label {
  color: #1d364b !important;
  font-weight: 600 !important;
}

.plugin-page label p {
  font-weight: 400 !important;
}

#content_wrapper {
  overflow: hidden !important;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 8px !important;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #999;
}

.border-chart {
  background: #fff;
  border-radius: 2px solid #e0e0e0;
  border-radius: 3px;
}

.dl-horizontal.list-dt-color dt {
  text-align: left;
  width: auto;
  padding-right: 1em;
  color: #8da2b5;
  font-weight: 400;
}

.dl-horizontal.list-dt-color dd {
  margin-left: 0;
  color: #8da2b5;
  font-weight: 400;
}

.th-check-center {
  margin-right: 0px;
  padding-right: 0px;
  margin-left: 6px;
}

.utility-page {
  height: 100% !important;
}

.intercom {
  display: none !important;
}

.lightbox-modal {
  z-index: 99999 !important;
}

div[uib-modal-backdrop="modal-backdrop"] {
  z-index: 99990 !important;
}

.panel-white {
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 1px 2px 0 #e5eaee;
  box-shadow: 1px 2px 0 #e5eaee;
  padding: 25px;
}

.activity-item {
  border-left: 2px solid #293d51;
  margin-left: 20px;
}

.activity-item .row {
  margin-left: -30px;
}

.dashboard-min-height {
  min-height: 635px;
}

.dashboard .loading,
.dashboard .gl-ldr-cld {
  position: absolute;
}

.dashboard {
  margin-top: -140px;
  padding: 20px;
}

.ui-select-container.has-error {
  border: 1px solid red;
}

.show-more {
  width: 100%;
  height: 25px;
  background: #eee;
  display: block;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-top: -25px;
  z-index: 99999999;
  position: relative;
}

.show-more a {
  margin: 0 auto;
  display: block;
  padding-top: 5px;
}

.show-more-scroll {
  overflow: scroll;
  height: 580px;
}

.filter-bottom {
  position: fixed;
  bottom: 130px;
  z-index: 9999;
  border-top: 1px solid #e0e0e0;
  margin-bottom: -15px;
  padding-bottom: 0px;
}

.table-scroll {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  overflow: scroll;
}

.ckbox {
  position: relative;
}

.ckbox input[type="checkbox"] {
  opacity: 0;
}

.ckbox label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ckbox label:before {
  content: "";
  top: 1px;
  left: 0;
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  border-radius: 2px;
  border: 1px solid #bbb;
  background-color: #fff;
}

.ckbox input[type="checkbox"]:checked + label:before {
  border-color: #2bbcde;
  background-color: #2bbcde;
}

.ckbox input[type="checkbox"]:checked + label:after {
  top: 3px;
  left: 3.5px;
  content: "\e013";
  color: #fff;
  font-size: 11px;
  font-family: "Glyphicons Halflings";
  position: absolute;
}

.table-filter .star {
  color: #ccc;
  text-align: center;
  display: block;
}

.table-filter .star.star-checked {
  color: #f0ad4e;
}

.table-filter .star:hover {
  color: #ccc;
}

.table-filter .star.star-checked:hover {
  color: #f0ad4e;
}

.table-filter .media-photo {
  width: 75px;
}

.table-filter .media-body {
  display: block;
  /* Had to use this style to force the div to expand (wasn't necessary with my bootstrap version 3.3.6) */
}

.table-filter .media-meta {
  font-size: 11px;
  color: #999;
}

.table-filter .media .title {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.table-filter .media .title span {
  font-size: 0.8em;
  margin-right: 20px;
}

span.pagado {
  color: #5cb85c;
}

span.pendiente {
  color: #f0ad4e;
}

span.cancelado {
  color: #d9534f;
}

.table-filter .media .summary {
  font-size: 14px;
}

.pagination-lg,
.total-record {
  width: 100%;
  text-align: center;
}

.sidebar-left-content li:last-child > a:hover {
  background: rgba(0, 0, 0, 0) !important;
}

.allcp-form .btn-warning,
.btn-warning {
  background-color: #b0b734;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #adaa07 !important;
  border-color: rgba(0, 0, 0, 0.05);
}

.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.small-box > .inner {
  padding: 10px;
  padding-top: 30px;
  text-align: center;
}

.small-box > .small-box-footer {
  position: relative;
  text-align: center;
  padding: 3px 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  z-index: 2;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.small-box > .small-box-footer:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.15);
}

.small-box h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 10px 0;
  white-space: nowrap;
  padding: 0;
}

.small-box p {
  font-size: 15px;
  font-weight: bold;
}

.small-box p > small {
  display: block;
  color: #f9f9f9;
  font-size: 13px;
  margin-top: 5px;
}

.small-box h3,
.small-box p,
.small-box h5 {
  z-index: 5;
  color: #fff;
}

.small-box .icon {
  position: absolute;
  top: -33px;
  background: #d1462f;
  border: 2px solid #e482bbfc9;
  border-radius: 50%;
  font-size: 40px;
  padding: 5px 10px;
  color: #fff;
  margin-left: -32px;
  left: 50%;
}

.small-box-icon-blue {
  background: #5dc1ea !important;
  border: 2px solid #8cd2ef !important;
}

.small-box-icon-green {
  background: #81d172 !important;
  border: 2px solid #b9f1ae !important;
}

.small-box-icon-yellow {
  background: #f0c80b !important;
  border: 2px solid #f1de86 !important;
}

.bg-fuchsia {
  background-color: #f012be !important;
}

.small-box-icon-fuchsia {
  background: #f012be !important;
  border: 2px solid #ef75d4 !important;
}

.small-box:hover {
  text-decoration: none;
  color: #f9f9f9;
}

@media (max-width: 767px) {
  .small-box {
    text-align: center;
  }

  .small-box .icon {
    display: none;
  }

  .small-box p {
    font-size: 12px;
  }
}

.bg-aqua {
  background-color: #00c0ef !important;
}

.small-box-icon-orange {
  background: #ff8b42 !important;
  border: 2px solid #f5b58d !important;
}

.col-lg-1-4 {
  position: relative;
  min-height: 1px;
  padding-left: 22px /*/ 2*/;
  padding-right: 22px /*/ 2*/;
  width: 14.28%;
  float: left;
}

.col-lg-2-4 {
  position: relative;
  min-height: 1px;
  padding-left: 22px /*/ 2*/;
  padding-right: 22px /*/ 2*/;
  width: 20%;
  float: left;
}

.select2-container .select2-choice {
  border: 1px solid #016377 !important;
  color: #fff !important;
  background-color: #2a7385 !important;
  background-image: none !important;
  font-weight: bold !important;
}

.select2-container .select2-choice .select2-arrow {
  border-left: 1px solid #016377 !important;
  background: #2a7385 !important;
  background-image: none !important;
}

.select2-container .select2-choice .select2-arrow b {
  display: block;
  height: 100%;
  background: url(select2.png) no-repeat 0 1px;
}

.select2-dropdown-open .select2-choice {
  box-shadow: 0 1px 0 #2a7385 inset !important;
}

.select2-search {
  padding-left: 1px !important;
  padding-top: 6px !important;
}

.cards-block {
  border-right: 5px solid #f6333f;
  margin-bottom: 25px;
}

.cards-block .square-box {
  width: 100px;
  margin-right: 22px;
  text-align: center !important;
  padding: 20px 0;
}

.cards-block.block-info {
  border-color: #20819e;
}

.cards-block.block-info .square-box {
  background-color: #20819e;
  color: #fff;
}

.navbar-center p {
  color: #ff9235;
  font-weight: bold;
}

tr.selected-row th {
  background: #016377 !important;
  color: #fff !important;
}

.offer-box {
  border: 2px solid #469aa7;
  padding: 20px;
  margin: 10px;
  border-radius: 6px;
}

.route-map {
  right: 0px !important;
  left: 36% !important;
}

.bg-gray-order {
  background-color: #efefef !important;
}

.map-button {
  z-index: 999;
  position: absolute;
  right: 58px;
  margin-top: 15px;
  padding: 11px;
  background: #fff;
  color: #666;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.route-sidebar-head a.data-tab {
  padding: 12px;
  background: #469aa7;
  color: #fff;
}

.route-sidebar-head a.data-tab.active {
  padding: 15px;
  background: #fff;
  color: #469aa7;
}

/* New Login Page */
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

body.utility-page #main {
  overflow: hidden;
  background: #f5f8fe !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "GoogleSans", sans-serif;
}

body.utility-page #content,
body.utility-page #content .allcp-form {
  margin-top: 6% !important;
}

.auth-page .panel {
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(211, 216, 224, 0.5);
  background-color: #ffffff;
  margin-top: 40px;
  padding: 0px !important;
}

.auth-page .panel-body {
  padding: 60px 60px 20px 60px !important;
}

.auth-page .panel-body.register {
  padding: 55px 55px 55px 55px !important;
}

.auth-page .panel-body.success {
  padding: 60px 60px 0px 60px !important;
}

.vehicle-type button {
  padding: 10px 19px;
  color: #2ebfc9;
  border-color: #2ebfc9;
  background: #fff;
}

.bg-desc {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828c95;
}

.auth-page .panel-footer {
  border-radius: 0 0 10px 10px;
  background-color: #f5f8fe !important;
  text-align: center;
}

.auth-page .panel-footer p {
  font-size: 14px;
  color: #828c95;
  text-align: center;
}

.auth-page .panel-footer p a {
  font-size: 14px;
  font-weight: bold;
  color: #ff9235;
}

.auth-page .gui-input {
  border: solid 1px #dbe4e5;
  border-radius: 8px;
  padding: 20px 12px;
  min-height: 48px;
}

.auth-page select.gui-input {
  padding: 0px 12px !important;
}

.auth-page .gui-input:focus {
  outline-color: transparent !important;
}

.auth-page h3 {
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  color: #4a5157;
  margin-bottom: 20px;
}

.hod-button {
  font-family: "GoogleSans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a5157;
  border: 1px solid #e5e5e5;
  border-radius: 22px;
  padding: 12px 20px;
  display: inline-block;
}

.hod-button.orange {
  background-color: #ff9235;
  border-color: #ff9235;
  color: #fff !important;
}

.hod-button.blue {
  background-color: #2bbfc9;
  border-color: #2bbfc9;
  color: #fff !important;
  font-weight: bold;
}

.hod-button.blue[disabled] {
  background-color: #aef4f9;
  border-color: #aef4f9;
  color: #fff !important;
  font-weight: bold;
}

.hod-button.blue.block {
  background-color: #2bbfc9;
  border-color: #2bbfc9;
  border-radius: 24px;
  color: #fff !important;
  display: block;
  width: 100%;
}

.hod-button.outline {
  background: #ffffff;
  border-color: #e5e5e5;
  border-radius: 24px;
  color: #2bbfc9 !important;
}

.auth-page-box {
  position: absolute;
  margin-top: -30px;
  margin-left: -30px;
}

.auth-page-boxes {
  margin-left: 320px;
}

.auth-page-box-forgot {
  position: absolute;
  margin-top: -90px;
  margin-left: 60px;
}

.auth-page .stepper-wrapper {
  margin: 10px auto;
}

.auth-page .stepper {
  width: 100%;
  margin: 50px auto;
  display: block;
}

.auth-page .stepper li {
  float: left;
  list-style-type: none;
  width: 18px;
  height: 19px;
  box-shadow: 0 0 4px 0 #d3d8e0;
  background-color: #ffffff;
  border-radius: 50%;
  text-align: center;
  margin-top: -1px;
  margin-right: 40px;
}

.auth-page .stepper li a {
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #828c95;
}

.auth-page .stepper:before {
  content: "";
  border-bottom: 2px dashed #d3d8e1;
  width: 275px;
  margin-left: 20px;
  height: 2px;
  display: block;
  margin-bottom: -10px;
}

.auth-page .stepper li.selected {
  width: 24px;
  height: 24px;
  background-color: #2bbfc9;
  color: #fff;
  line-height: 24px;
  margin-top: -6px;
}

.allcp-form .option-info input:checked + .checkbox,
.allcp-form .option-info input:focus + .checkbox,
.allcp-form .option-info input:checked + .radio,
.allcp-form .option-info input:focus + .radio {
  border-color: #2dbfc9 !important;
}

.auth-page .stepper.success li {
  margin-right: -7px;
  box-shadow: 0 0 4px 0 #d3d8e0;
}

.auth-page .stepper.success {
  width: 60%;
}

.auth-page .stepper.success:before {
  width: 115px;
}

.auth-page .stepper li.selected.success {
  margin-left: 50px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: -15px;
}

.auth-page .stepper li.selected a {
  color: #fff;
  line-height: 24px;
}

.footer-copy {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828c95;
}

#registerForm .ng-invalid.ng-touched {
  border: 2px solid #d9272d !important;
}

.credit-area {
  height: 186px;
  border-radius: 8px;
  border: solid 1px #dbe4e5;
  background-color: #ffffff;
  overflow: auto;
  padding: 0px 15px;
}

p.error {
  color: red !important;
}

.btn-register-bc {
  width: 100%;
  height: 48px;
  line-height: 14px;
  border-radius: 8px;
  border: solid 1px #dbe4e5;
  background-color: #ffffff;
}

.credit-area p {
  font-size: 12px;
  color: #828c95;
}

.animate-if.ng-enter,
.animate-if.ng-leave {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  opacity: 0;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
  opacity: 0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  opacity: 1;
}

.auth-logo {
  margin: 0 auto;
}

.login-panel .panel-body a {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a5157;
}

.show-hide-pass a {
  position: absolute;
  right: 10px;
  top: 16px;
  z-index: 9999;
}

.show-hide-pass a i {
  font-size: 20px;
}

/* Mobile */

@media only screen and (max-width: 600px) {
  .auth-page {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0px;
    margin: 0px;
  }

  .auth-page .panel {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
  }

  .auth-page-box,
  .auth-page-box-forgot,
  .auth-page-boxes {
    display: none;
  }

  .auth-page .header-logo {
    display: block;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .auth-page .header-logo img {
    float: left !important;
  }

  .auth-page .panel-body.register {
    padding: 30px !important;
    /* height: calc(100vh - 140px); */
  }

  .auth-page.login-panel {
    background: #fff;
    margin-top: -6% !important;
    height: 100vh;
  }

  .auth-page.login-panel .panel {
    box-shadow: none !important;
  }

  .auth-page.login-panel .footer-copy {
    display: none !important;
  }

  .auth-page.login-panel .panel-footer {
    position: fixed;
    width: 100%;
    bottom: 0px;
  }

  .auth-page.login-panel .panel-body {
    padding: 25px !important;
  }

  .auth-logo {
    margin-left: 30px;
  }

  body.utility-page #content .allcp-form.login-panel {
    padding-top: 50% !important;
  }

  body.utility-page #content .allcp-form.login-panel {
    margin-top: 0% !important;
  }

  body.utility-page #content {
    margin-top: 0% !important;
  }

  .panel-body.register.pn.mv10.success {
    height: calc(100vh - 330px) !important;
  }

  .auth-page .stepper li {
    margin-right: 34px;
  }

  .auth-page .stepper:before {
    width: 260px;
  }

  #disclosure .modal-dialog,
  #docuSignModal .modal-dialog,
  #authorization .modal-dialog {
    width: 100% !important;
  }
}

.btn-small {
  padding: 3px !important;
  font-size: 10px !important;
}

.btn-small span {
  font-size: 10px !important;
}

.selectedOrders {
  width: 250px;
  right: 20px;
  position: absolute;
  background: #fff;
  margin-top: 90px;
  height: 300px;
  overflow: auto;
  padding: 20px;
}

#scrollable-area {
  margin: auto;
  width: 100%;
  /* height: calc(100vh - 320px); */
  /* overflow-y: scroll; */
}

.tableFloatingHeaderOriginal {
  background: #f6f9ff;
  margin-top: -5px !important;
}

.paginate-area {
  padding-top: 20px;
}

.pagination > li > a,
.pagination > li > span {
  padding: 0 16px;
  line-height: 48px;
  border-radius: 8px;
  margin: 0 10px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 8px;
}

.main-message {
  height: 35px;
  position: fixed;
  z-index: 999999;
  width: 100%;
  text-align: center;
  line-height: 35px;
  color: #fff;
}

.main-message.error {
  background: #ed2b2c;
}

.main-message.success {
  background: #1bca6b;
}

.alert-white {
  background-color: #fff;
  color: #000 !important;
}

#disclosure,
#authorization,
#docuSignModal {
  height: 100vh;
  padding-left: 0px;
}

#disclosure .modal-title,
#docuSignModal .modal-title,
#authorization .modal-title {
  letter-spacing: 0px;
}

#disclosure .modal-dialog,
#authorization .modal-dialog {
  width: 60%;
  margin: 0 auto;
  height: 100vh;
}

#docuSignModal .modal-dialog {
  width: 90%;
  margin: 0 auto;
  height: 100vh;
}

#disclosure .modal-header,
#docuSignModal .modal-header,
#authorization .modal-header {
  padding: 10px;
}

#disclosure .modal-content,
#docuSignModal .modal-content,
#authorization .modal-content {
  height: 100vh;
  max-height: 100vh;
}

#disclosure .modal-body,
#docuSignModal .modal-body,
#authorization .modal-body {
  height: 60vh;
  padding: 0px;
}

#disclosure .modal-footer .btn,
#authorization .modal-footer .btn,
#docuSignModal .modal-footer .btn {
  background-color: #2bbfc9;
}

.footer-copy p {
  font-size: 12px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828c95 !important;
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 1140px;
  margin: auto;
}

.footer-copy ul li {
  float: left;
  list-style-type: none !important;
  text-align: right;
  margin-left: 10px;
}

.footer-copy ul li a {
  font-size: 12px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828c95 !important;
  text-align: right;
}

.chat-content {
  margin-top: 70px !important;
}

#frame {
  width: 100%;
  min-width: 360px;
  height: calc(100vh - 150px);
  min-height: 300px;
  margin-top: 150px;
}

@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
}

#frame #sidepanel {
  float: left;
  min-width: 280px;
  max-width: 340px;
  width: 40%;
  height: 100%;
  background: #f6f9ff;
  color: #4a5157;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel {
    width: 58px;
    min-width: 58px;
  }
}

#frame #sidepanel #search {
  border-top: 1px solid #109098;
  border-bottom: 1px solid #109098;
  font-weight: 300;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #search {
    display: none;
  }
}

#frame #sidepanel #search label {
  position: absolute;
  margin: 20px 0 0 20px;
}

#frame #sidepanel #search input {
  padding: 20px 0 20px 46px;
  width: 100%;
  border: none;
  background: #109098;
  color: #f5f5f5;
}

#frame #sidepanel #search input:focus {
  outline: none;
  background: #435f7a;
}

#frame #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #contacts {
  height: calc(100% - 1px);
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts {
    height: calc(100% - 149px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #frame #sidepanel #contacts::-webkit-scrollbar {
    display: none;
  }
}

#frame #sidepanel #contacts::-webkit-scrollbar {
  width: 10px;
  background: #2bbfc9;
}

#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #243140;
}

#frame #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}

#frame #sidepanel #contacts ul {
  padding: 0;
  margin-top: 130px;
}

#frame #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 6px 0 6px 0;
  font-size: 0.9em;
  cursor: pointer;
  list-style-type: none;
  width: 100%;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 46px 8px;
  }
}

#frame #sidepanel #contacts ul li.contact:hover {
  background: #2bbfc9;
  color: #fff;
}

#frame #sidepanel #contacts ul li.contact.active {
  background: #2bbfc9;
  color: #fff;
}

#frame #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #2bbfc9 !important;
}

#frame #sidepanel #contacts ul li.contact .wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #333;
  padding: 0px;
  width: 100%;
  padding: 2px 6px;
  margin-bottom: -6px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
  }
}

#frame #sidepanel #contacts ul li.contact .wrap span.online {
  position: absolute;
  left: 36px;
  top: 15px;
  /* margin: -2px 0 0 -2px; */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #2bbfc9;
  background: #95a5a6;
}

#frame #sidepanel #contacts ul li.contact .wrap span.counter {
  position: absolute;
  left: 0;
  /* margin: 20px 0 0 -12px; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* border: 2px solid #2bbfc9; */
  background: #2ecc71;
}

li.offline {
  opacity: 0.5;
}

#frame #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71;
}

#frame #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f;
}

#frame #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c;
}

#frame #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
  }
}

#frame #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap .meta {
    display: none;
  }
}

#frame #sidepanel #contacts ul li.contact .wrap .meta p {
  margin: 0px;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-weight: 600;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
  margin: 5px 0px;
  padding: 0 0 1px;
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta small.preview {
  margin: 5px 0px;
  padding: 0 0 1px;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: 0.6;
}

#frame #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}

#frame #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 100%;
  padding: 10px 0;
  background: #109098;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 14px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }
}

#frame #sidepanel #bottom-bar button:focus {
  outline: none;
}

#frame #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid #2bbfc9;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2bbfc9;
  }
}

#frame #sidepanel #bottom-bar button:hover {
  background: #435f7a;
}

#frame #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button span {
    display: none;
  }
}

#frame .content {
  float: right;
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 735px) {
  #frame .content {
    width: calc(100% - 58px);
    min-width: 300px !important;
  }
}

@media screen and (min-width: 900px) {
  #frame .content {
    width: calc(100% - 340px);
  }
}

#frame .content .contact-profile {
  width: 100%;
  height: 67px;
  line-height: 67px;
  background: #2bbfc9;
}

#frame .content .contact-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px;
}

#frame .content .contact-profile p {
  color: #fff;
  float: left;
  padding: 0px;
  margin: 0px;

  font-size: 18px;
  font-weight: 600;
}

#frame .content .contact-profile .social-media {
  float: right;
}

#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}

#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}

#frame .content .contact-profile .social-media i:hover {
  color: #435f7a;
}

#frame .content .messages {
  height: auto;
  min-height: calc(100% - 140px);
  max-height: calc(100% - 140px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

@media screen and (max-width: 735px) {
  #frame .content .messages {
    max-height: calc(100% - 105px);
  }
}

#frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

#frame .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

#frame .content .messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  font-size: 0.9em;
}

#frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}

#frame .content .messages ul li span {
  display: block;
  color: #828c95;
}

#frame .content .messages ul li.sent span {
}

#frame .content .messages ul li.replies span {
  float: right;
  width: 100%;
  text-align: right;
  font-size: 14px;
}

#frame .content .messages ul li.sent img {
  margin: 6px 8px 0 0;
}

#frame .content .messages ul li.sent .message-body {
  background: #2bbfc9;
  color: #f5f5f5;
  margin: 0px;
  margin-bottom: 10px;
  margin: 0px;
  margin-bottom: 10px;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 400px;
  line-height: 130%;
  font-size: 14px;
}

#frame .content .messages ul li.replies img {
  float: right;
  margin: 6px 0 0 8px;
}

#frame .content .messages ul li.replies .message-body {
  background: #fff;
  float: right;
  margin: 0px;
  margin-bottom: 10px;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 400px;
  line-height: 130%;
  font-size: 20px;
}

#frame .content .messages ul li img {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  float: left;
}

#frame .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 205px;
  line-height: 130%;
  font-size: 14px;
}

@media screen and (min-width: 735px) {
  #frame .content .messages ul li p {
    max-width: 300px;
  }
}

#frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

#frame .content .message-input .wrap {
  position: relative;
  background: #fff;
  width: 100%;
  display: block;
  height: 45px;
}

#frame .content .message-input .wrap textarea {
  float: left;
  border: none;
  width: calc(100% - 100px);
  padding: 14px 32px 14px 8px;
  font-size: 14px;
  color: #109098;
  font-size: 18px;
  height: 100px;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap textarea {
    padding: 15px 32px 16px 8px;
  }
}

#frame .content .message-input .wrap textarea:focus {
  outline: none;
}

#frame .content .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: 0.5;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}

#frame .content .message-input .wrap .attachment:hover {
  opacity: 1;
}

#frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 100px;
  padding: 12px 0;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap button {
    padding: 16px 0;
  }
}

#frame .content .message-input .wrap button:hover {
  background: #435f7a;
}

#frame .content .message-input .wrap button:focus {
  outline: none;
}

.edit-route-left {
  left: 136px;
  width: 33% !important;
  background: #f6f9ff !important;
  margin-top: 0px !important;
}

#orderDetail {
  z-index: 99999999;
}

.ui-select-multiple.ui-select-bootstrap {
  padding: 12px;
}

.rt-item-name {
  width: 100px;
}

.btn-toolbar .btn {
  padding: 8px !important;
}
.btn-toolbar .btn-group {
  display: block;
  width: 100%;
  margin-bottom: 6px;
}

.route-stops .step {
  display: block !important;
}

.order-number {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dbe4e5;
  background: #fff !important;
  border-radius: 50%;
  font-weight: bold;
  font-size: 18px !important;
  color: #1bbfca;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-number-fail {
  color: #f44336 !important;
  border-color: #f44336 !important;
}

.order-number-complete {
  color: #2bbfc9 !important;
  border-color: #2bbfc9 !important;
}

.order-number-last {
  color: #ff9234 !important;
  border-color: #ff9234 !important;
}

.xdsoft_datetimepicker .xdsoft_timepicker {
  /* changed from 58px */
  width: 88px;
  float: left;
  text-align: center;
  margin-left: 8px;
  margin-top: 0px;
}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
  float: none;
  background-position: -40px -15px;
  height: 15px;
  width: 30px;
  display: block;
  /* changed from 14px */
  margin-left: 29px;
  margin-top: 7px;
}

.highlighted {
  background-color: #ffeb3b;
  padding: 2px 4px;
  color: #a72800;
  margin: 0 2px;
  font-weight: bold;
}

.new-messasge {
  background: #2ecc71;
}

#workerDetail {
  z-index: 1052;
}

.routing-list {
  height: calc(100% - 189px);
  overflow: auto;
}

.color-FF6633 span {
  color: #ff6633 !important;
}
.color-FF33FF span {
  color: #ff33ff !important;
}
.color-FFFF99 span {
  color: #ffff99 !important;
}
.color-00B3E6 span {
  color: #00b3e6 !important;
}
.color-E6B333 span {
  color: #e6b333 !important;
}
.color-3366E6 span {
  color: #3366e6 !important;
}
.color-999966 span {
  color: #999966 !important;
}
.color-99FF99 span {
  color: #99ff99 !important;
}
.color-B34D4D span {
  color: #b34d4d !important;
}
.color-80B300 span {
  color: #80b300 !important;
}
.color-809900 span {
  color: #809900 !important;
}
.color-E6B3B3 span {
  color: #e6b3b3 !important;
}
.color-6680B3 span {
  color: #6680b3 !important;
}
.color-66991A span {
  color: #66991a !important;
}
.color-FF99E6 span {
  color: #ff99e6 !important;
}
.color-CCFF1A span {
  color: #ccff1a !important;
}
.color-FF1A66 span {
  color: #ff1a66 !important;
}
.color-E6331A span {
  color: #e6331a !important;
}
.color-33FFCC span {
  color: #33ffcc !important;
}
.color-66994D span {
  color: #66994d !important;
}
.color-B366CC span {
  color: #b366cc !important;
}
.color-4D8000 span {
  color: #4d8000 !important;
}
.color-B33300 span {
  color: #b33300 !important;
}
.color-CC80CC span {
  color: #cc80cc !important;
}
.color-66664D span {
  color: #66664d !important;
}
.color-991AFF span {
  color: #991aff !important;
}
.color-E666FF span {
  color: #e666ff !important;
}
.color-4DB3FF span {
  color: #4db3ff !important;
}
.color-1AB399 span {
  color: #1ab399 !important;
}
.color-E666B3 span {
  color: #e666b3 !important;
}
.color-33991A span {
  color: #33991a !important;
}
.color-CC9999 span {
  color: #cc9999 !important;
}
.color-B3B31A span {
  color: #b3b31a !important;
}
.color-00E680 span {
  color: #00e680 !important;
}
.color-4D8066 span {
  color: #4d8066 !important;
}
.color-809980 span {
  color: #809980 !important;
}
.color-E6FF80 span {
  color: #e6ff80 !important;
}
.color-1AFF33 span {
  color: #1aff33 !important;
}
.color-999933 span {
  color: #999933 !important;
}
.color-FF3380 span {
  color: #ff3380 !important;
}
.color-CCCC00 span {
  color: #cccc00 !important;
}
.color-66E64D span {
  color: #66e64d !important;
}
.color-4D80CC span {
  color: #4d80cc !important;
}
.color-9900B3 span {
  color: #9900b3 !important;
}
.color-E64D66 span {
  color: #e64d66 !important;
}
.color-4DB380 span {
  color: #4db380 !important;
}
.color-FF4D4D span {
  color: #ff4d4d !important;
}
.color-99E6E6 span {
  color: #99e6e6 !important;
}
.color-6666FF span {
  color: #6666ff !important;
}
.color-FFB399 span {
  color: #ffb399 !important;
}

.remaining-time {
  width: 50px;
  padding: 1px;
  border-radius: 8px;
  background: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remaining-time h3,
.remaining-time h5 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.top200 {
  top: 240px !important;
  height: calc(100% - 240px) !important;
}

.top250 {
  top: 250px !important;
  height: calc(100% - 250px) !important;
}

.worker-Car .text {
  color: #ff008c !important;
}
.worker-Scooter .text {
  color: #40eb04 !important;
}
.worker-Bike .text {
  color: #c1db22 !important;
}
.worker-Motorcycle .text {
  color: #ffd900 !important;
}
.worker-Suv .text {
  color: #bc00fe !important;
}
.worker-Van .text {
  color: #1829ff !important;
}
.worker-Truck .text {
  color: #10dfb9 !important;
}

.bc-phone-number-dropdown-menu {
  overflow-y: scroll;
  max-height: 10em;
}
.bc-phone-number-country-anchor {
  padding-left: 1em;
}
.bc-phone-number-country-icon {
  margin-right: 1em;
}
.bc-phone-number-flag {
  margin-right: 0.5em;
}

.cancelled-orders {
  position: absolute;
  background: #fff;
  right: 20px;
  top: 20px;
  z-index: 9999999;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;
  border-radius: 12px;
  border: 1px solid #eee;
}

.not-printed {
  font-size: 16px;
}

.printed {
  color: #179a2d !important;
}

.marker-label-position {
  top: 23px;
  left: 0;
  position: relative;
  padding: 4px;
  background: #d6d6d6;
  border-radius: 4px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.customer-label-position {
  top: 0px;
  left: 0;
  position: relative;
  padding: 4px;
  color: #fff;
  /* background: #d6d6d6; */
  border-radius: 4px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

@-moz-keyframes pulsate {
  from {
    -moz-transform: scale(0.25);
    opacity: 1;
  }
  95% {
    -moz-transform: scale(1.3);
    opacity: 0;
  }
  to {
    -moz-transform: scale(0.3);
    opacity: 0;
  }
}
@-webkit-keyframes pulsate {
  from {
    -webkit-transform: scale(0.25);
    opacity: 1;
  }
  95% {
    -webkit-transform: scale(1.3);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(0.3);
    opacity: 0;
  }
}

#map div[title="New Order Animation"] {
  -moz-animation: pulsate 1.5s ease-in-out infinite;
  -webkit-animation: pulsate 1.5s ease-in-out infinite;
  border: 1pt solid #fff;
  /* make a circle */
  -moz-border-radius: 51px;
  -webkit-border-radius: 51px;
  border-radius: 51px;
  /* multiply the shadows, inside and outside the circle */
  -moz-box-shadow: inset 0 0 5px #2bbfc9, inset 0 0 5px #2bbfc9,
    inset 0 0 5px #2bbfc9, 0 0 5px #2bbfc9, 0 0 5px #2bbfc9, 0 0 5px #2bbfc9;
  -webkit-box-shadow: inset 0 0 5px #2bbfc9, inset 0 0 5px #2bbfc9,
    inset 0 0 5px #2bbfc9, 0 0 5px #2bbfc9, 0 0 5px #2bbfc9, 0 0 5px #2bbfc9;
  box-shadow: inset 0 0 5px #2bbfc9, inset 0 0 5px #2bbfc9,
    inset 0 0 5px #2bbfc9, 0 0 5px #2bbfc9, 0 0 5px #2bbfc9, 0 0 5px #2bbfc9;
  /* set the ring's new dimension and re-center it */
  height: 51px !important;
  margin: -9px 0 0 -8px;
  width: 51px !important;
}
/* hide the superfluous marker image since it would expand and shrink with its containing element */
/*	#map div[style*="987654"][title] img {*/
#map div[title="New Order Animation"] img {
  display: none;
}
/* compensate for iPhone and Android devices with high DPI, add iPad media query */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (device-width: 768px) {
  #map div.gmnoprint[title="New Order Animation"] {
    margin: -10px 0 0 -10px;
  }
}

.register-merchant {
}

.register-merchant .stepper li.selected {
  width: 24px;
  height: 24px;
  background-color: #f28b2f;
  color: #fff;
  line-height: 24px;
  margin-top: -6px;
}

.register-merchant button[disabled],
.register-merchant input[disabled] {
  background-color: #ffcfa6;
  border-color: #ffc99c;
}

.register-merchant .option-info input:checked + .checkbox,
.allcp-form .option-info input:focus + .checkbox,
.allcp-form .option-info input:checked + .radio,
.allcp-form .option-info input:focus + .radio {
  border-color: #f28b2f !important;
}

.register-merchant .option-info input:checked + .checkbox:before,
.allcp-form .option-info input:focus + .checkbox:before {
  border-color: #f28b2f;
}

.register-merchant .allcp-form .option-info input:checked + .checkbox,
.register-merchant .allcp-form .option-info input:focus + .checkbox,
.register-merchant .allcp-form .option-info input:checked + .radio,
.register-merchant .allcp-form .option-info input:focus + .radio {
  border-color: #f28b2f !important;
}

.node.big-commpany {
  border: 2px solid #ff9235;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
  cursor: pointer;
}

.node.big-commpany .node-name {
  padding: 0px;
  margin: 0px;
}
