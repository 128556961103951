#loading-bar,#loading-bar-spinner {
    pointer-events: none;
    -webkit-pointer-events: none;
    -webkit-transition: 350ms linear all;
    -moz-transition: 350ms linear all;
    -o-transition: 350ms linear all;
    transition: 350ms linear all
}

#loading-bar-spinner.ng-enter,#loading-bar-spinner.ng-leave.ng-leave-active,#loading-bar.ng-enter,#loading-bar.ng-leave.ng-leave-active {
    opacity: 0
}

#loading-bar-spinner.ng-enter.ng-enter-active,#loading-bar-spinner.ng-leave,#loading-bar.ng-enter.ng-enter-active,#loading-bar.ng-leave {
    opacity: 1
}

#loading-bar .bar {
    -webkit-transition: width 350ms;
    -moz-transition: width 350ms;
    -o-transition: width 350ms;
    transition: width 350ms;
    background: #fff;
    position: fixed;
    z-index: 10002;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-bottom-right-radius: 1px;
    border-top-right-radius: 1px
}

#loading-bar .peg {
    position: absolute;
    width: 70px;
    right: 0;
    top: 0;
    height: 2px;
    opacity: .45;
    -moz-box-shadow: #ff9235 1px 0 6px 1px;
    -ms-box-shadow: #ff9235 1px 0 6px 1px;
    -webkit-box-shadow: #ff9235 1px 0 6px 1px;
    box-shadow: #ff9235 1px 0 6px 1px;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%
}

#loading-bar-spinner {
    display: block;
    position: fixed;
    z-index: 10002;
    top: 50%;
    left: 50%
}

#loading-bar-spinner .spinner-icon {
    width: 30px;
    height: 30px;
    border: 2px solid transparent;
    border-top-color: #ff9235;
    border-left-color: #ff9235;
    border-radius: 50%;
    -webkit-animation: loading-bar-spinner 400ms linear infinite;
    -moz-animation: loading-bar-spinner 400ms linear infinite;
    -ms-animation: loading-bar-spinner 400ms linear infinite;
    -o-animation: loading-bar-spinner 400ms linear infinite;
    animation: loading-bar-spinner 400ms linear infinite
}

@-webkit-keyframes loading-bar-spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-moz-keyframes loading-bar-spinner {
    0% {
        -moz-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-o-keyframes loading-bar-spinner {
    0% {
        -o-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-ms-keyframes loading-bar-spinner {
    0% {
        -ms-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}
