.allcp-form,
.allcp-form * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.allcp-form {
  line-height: 1.231;
  font-weight: 400;
  font-size: 14px;
  color: #626262;
}
.allcp-form .section {
  margin-bottom: 22px;
}
.allcp-form .gui-input,
.allcp-form .gui-textarea,
.allcp-form .select > select,
.allcp-form input[type="button"],
.allcp-form input[type="submit"],
.allcp-form input[type="search"],
.allcp-form .select-multiple select {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.allcp-form input[type="search"]::-webkit-search-decoration,
.allcp-form input[type="search"]::-webkit-search-cancel-button,
.allcp-form input[type="search"]::-webkit-search-results-button,
.allcp-form input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.allcp-form .radio,
.allcp-form .button,
.allcp-form .checkbox,
.allcp-form .select .arrow,
.allcp-form .switch > label,
.allcp-form .ui-slider .ui-slider-handle {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.allcp-form label,
.allcp-form input,
.allcp-form button,
.allcp-form select,
.allcp-form textarea {
  margin: 0;
  outline: none;
}
.allcp-form .radio,
.allcp-form .button,
.allcp-form .tooltip,
.allcp-form .checkbox,
.allcp-form .gui-input,
.allcp-form .input-footer,
.allcp-form .notification,
.allcp-form .gui-textarea,
.allcp-form .select > select,
.allcp-form .select-multiple select {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: none;
}
.allcp-form .input-link,
.allcp-form .panel-heading,
.allcp-form .section-divider span,
.allcp-form .select > select,
.allcp-form .select-multiple select {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.allcp-form .spacer {
  border-top: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  height: 0;
}
.allcp-form .theme-link {
  color: #469aa7;
  text-decoration: none;
}
.allcp-form .theme-link:hover {
  text-decoration: underline;
}
.allcp-form .section-divider {
  height: 0;
  border-top: 1px solid #DDD;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.allcp-form .section-divider span {
  display: inline-block;
  position: relative;
  padding: 0 17px;
  background: #e9e9e9;
  color: #999;
  top: -11px;
  font-size: 15px;
}
.allcp-form.panel .section-divider span,
.allcp-form .panel .section-divider span,
.allcp-form.tab-content .section-divider span,
.allcp-form .tab-content .section-divider span {
  background: #fff;
}
.allcp-form.bg-light .section-divider span,
.allcp-form .bg-light .section-divider span {
  background: #FAFAFA;
}
.allcp-form.bg-light.light .section-divider span,
.allcp-form .bg-light.light .section-divider span {
  background: #FEFEFE;
}
.allcp-form.bg-light.dark .section-divider span,
.allcp-form .bg-light.dark .section-divider span {
  background: #F2F2F2;
}
.allcp-form.well .section-divider span,
.allcp-form .well .section-divider span {
  background: #f5f5f5;
}
.allcp-form .switch,
.allcp-form .option,
.allcp-form .field-label {
  font-size: 14px;
}
.allcp-form .field-label[class*='col-'] {
  line-height: 42px;
}
.allcp-form .field-align {
  line-height: 42px;
}
.allcp-form .radio,
.allcp-form .option,
.allcp-form .checkbox {
  cursor: pointer;
}
.allcp-form .field {
  display: block;
  position: relative;
}
.allcp-form .field-icon i {
  color: #469aa7;
  position: relative;
}
.allcp-form .field-icon.text-primary i {
  color: #469aa7;
}
.allcp-form .field-icon.text-alert i {
  color: #ffbc0b;
}
.allcp-form .field-icon.text-warning i {
  color: #d4460f;
}
.allcp-form .field-icon.text-success i {
  color: #c3d62d;
}
.allcp-form .field-icon.text-info i {
  color: #4fd8b0;
}
.allcp-form .field-icon.text-dark i {
  color: #2a2f43;
}
.allcp-form .field-icon.text-system i {
  color: #5a5386;
}
.allcp-form .field-icon.text-danger i {
  color: #f5393d;
}
.allcp-form .field-label {
  display: block;
  margin-bottom: 7px;
}
.allcp-form .field-label em {
  color: #e74c3c;
  font-size: 14px;
  font-style: normal;
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: 3px;
}
.allcp-form .gui-input,
.allcp-form .gui-textarea {
  padding: 10px;
}
.allcp-form .select,
.allcp-form .gui-input,
.allcp-form .gui-textarea,
.allcp-form .select > select,
.allcp-form .select-multiple select {
  position: relative;
  vertical-align: top;
  border: 1px solid #DDD !important;
  display: -moz-inline-stack;
  display: inline-block;
  *display: inline;
  color: #626262;
  outline: none;
  height: 42px;
  width: 100%;
  *zoom: 1;
}
.allcp-form .select option {
  background: #fff;
}
.allcp-form select[disabled],
.allcp-form .select > select[disabled] {
  color: #aaa !important;
}
.allcp-form .gui-textarea {
  resize: none;
  line-height: 19px;
  overflow: auto;
  max-width: 100%;
  height: 96px;
}
.allcp-form .select {
  border: 0;
  z-index: 10;
  background: #FFF;
}
.allcp-form .select > select {
  display: block;
  z-index: 10;
  margin: 0;
  padding: 9px 10px;
  color: #626262;
  outline: none;
  text-indent: 0.01px;
  text-overflow: '';
  background: #FFF;
  border: 1px solid #DDD;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: normal;
}
.allcp-form .select:before,
.allcp-form .select:after {
  background: #FFF;
}
.allcp-form .select > select::-ms-expand {
  display: none;
}
.allcp-form .select .arrow {
  pointer-events: none;
  position: absolute;
  top: 9px;
  right: 4px;
  width: 24px;
  height: 24px;
  color: #9F9F9F;
  z-index: 100;
}
.allcp-form .select .arrow:after,
.allcp-form .select .arrow:before {
  content: '';
  position: absolute;
  font: 12px "Consolas", monospace;
  font-style: normal;
  pointer-events: none;
  display: none\9;
  left: 5px;
}
.allcp-form .select .arrow:before {
  content: '\25BC';
  bottom: 4px;
}
.allcp-form .select .double:after {
  content: '\25B2';
  top: -1px;
}
.allcp-form .select .double:before {
  content: '\25BC';
  bottom: -1px;
}
.allcp-form .select-multiple select {
  width: 100%;
  height: 123px;
  padding: 10px;
}
.allcp-form .file {
  display: block;
  width: 100%;
}
.allcp-form .file .gui-file {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 8px 10px;
  position: absolute;
  -moz-opacity: 0;
  opacity: 0;
  z-index: 11;
  bottom: 0;
  right: 0;
}
.allcp-form .file .button {
  position: absolute;
  top: 4px;
  right: 4px;
  float: none;
  height: 34px;
  line-height: 34px;
  padding: 0 16px;
  z-index: 10;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.allcp-form .file.append-button .button {
  left: 4px;
  right: auto;
}
.allcp-form .file.append-button > input.gui-input {
  padding-right: 40px;
  padding-left: 125px;
  text-align: right;
}
.allcp-form .file.append-button > label.field-icon {
  right: 4px;
  left: auto;
}
.allcp-form .gui-input:hover,
.allcp-form .gui-textarea:hover,
.allcp-form .select > select:hover,
.allcp-form .select-multiple select:hover,
.allcp-form .gui-input:hover ~ .input-footer,
.allcp-form .file .gui-file:hover + .gui-input,
.allcp-form .gui-textarea:hover ~ .input-footer {
  border-color: #888;
}
.allcp-form .gui-input:focus,
.allcp-form .gui-textarea:focus,
.allcp-form .select > select:focus,
.allcp-form .select-multiple select:focus {
  color: #3c3c3c;
  background: #fff;
  border: 1px solid #999;
  outline: none;
}
.allcp-form .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form .gui-textarea:focus {
  height: 120px;
}
.allcp-form .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form .gui-input:focus ~ .field-icon i,
.allcp-form .gui-textarea:focus ~ .field-icon i {
  color: #999;
}
.allcp-form .select-multiple select:focus,
.allcp-form .gui-input:focus ~ .input-footer,
.allcp-form .gui-textarea:focus ~ .input-footer,
.allcp-form .file .gui-file:focus + .gui-input {
  border-color: #999;
}
.allcp-form .select > select:focus + .arrow {
  color: #999;
}
.allcp-form .option {
  position: relative;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}
.allcp-form .option > input {
  position: absolute;
  height: inherit;
  width: inherit;
  opacity: 0;
  left: 0;
}
.allcp-form .checkbox:before,
.allcp-form .radio:before {
  content: '';
  display: none;
}
.allcp-form input:checked + .checkbox:before,
.allcp-form input:checked + .radio:before {
  display: block;
}

.allcp-form input:checked + .checkbox,
.allcp-form input:checked + .radio {
  border: 2px solid #aaaaaa;
}
.allcp-form .radio {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}
.allcp-form .radio:before {
  margin: 5px;
  width: 7px;
  height: 7px;
  background: #aaa;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
.allcp-form input:not([disabled]):hover + .checkbox,
.allcp-form input:not([disabled]):hover + .radio {
  border-color: #aaa;
}
.allcp-form input:focus + .checkbox,
.allcp-form input:focus + .radio {
  border-color: #aaa;
}
.allcp-form input:focus + .radio:before {
  background: #aaa;
}
.allcp-form input:focus + .checkbox:before {
  border-color: #aaa;
}
.allcp-form .switch {
  cursor: pointer;
  position: relative;
  padding-right: 10px;
  display: inline-block;
  margin-bottom: 5px;
  height: 26px;
}
.allcp-form .switch > label {
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: 25px;
  width: 58px;
  color: #b9bbc5;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  background: transparent;
  border: 2px solid #b9bbc5;
  text-transform: uppercase;
  font-family: "GoogleSans", Arial, sans-serif;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.allcp-form .switch > label + span {
  display: inline-block;
  padding-left: 5px;
  position: relative;
  top: -7px;
}
.allcp-form .switch > label:before {
  content: attr(data-off);
  position: absolute;
  top: 1px;
  right: 3px;
  width: 33px;
}
.allcp-form .switch > label:after {
  content: "";
  margin: 2px;
  width: 17px;
  height: 17px;
  display: block;
  background: #b9bbc5;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.allcp-form .switch > input {
  -webkit-appearance: none;
  position: absolute;
  width: inherit;
  height: inherit;
  opacity: 0;
  left: 0;
  top: 0;
}
.allcp-form .switch > input:focus {
  outline: none;
}
.allcp-form .switch > input:checked + label {
  border-color: #aaa;
  background: transparent;
  padding-left: 33px;
  color: #aaa;
}
.allcp-form .switch > input:checked + label:before {
  content: attr(data-on);
  left: 1px;
  top: 1px;
}
.allcp-form .switch > input:checked + label:after {
  margin: 2px;
  width: 17px;
  height: 17px;
  background: #aaa;
}
.allcp-form .switch > input:checked:focus + label {
  background: transparent;
  border-color: #aaa;
}
.allcp-form .switch-round > label {
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -o-border-radius: 13px;
  border-radius: 13px;
}
.allcp-form .switch-round > label + span {
  top: -2px;
}
.allcp-form .switch-round > label:before {
  width: 33px;
}
.allcp-form .switch-round > label:after {
  width: 17px;
  color: #b9bbc5;
  content: "\2022";
  font: 20px/20px Times, Serif;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -o-border-radius: 13px;
  border-radius: 13px;
}
.allcp-form .switch-round > input:checked + label {
  padding-left: 33px;
}
.allcp-form .switch-round > input:checked + label:after {
  color: #aaa;
}
.allcp-form .switch-custom > label {
  color: #fa9a9c;
  border-color: #fa9a9c;
}
.allcp-form .switch-custom > label:after {
  background: #fa9a9c;
}
.allcp-form .switch-custom > input:hover + label,
.allcp-form .switch-custom > input:focus + label {
  color: #f42a2f;
  border-color: #f42a2f;
}
.allcp-form .switch-custom > input:hover + label:after,
.allcp-form .switch-custom > input:focus + label:after {
  background: #f42a2f;
}
.allcp-form .switch-custom > input:checked + label {
  color: #c3d62d;
  border-color: #c3d62d;
}
.allcp-form .switch-custom > input:checked + label:after {
  background: #c3d62d;
}
.allcp-form .switch-custom > input:checked:hover + label,
.allcp-form .switch-custom > input:checked:focus + label {
  color: #8b991e;
  border-color: #8b991e;
}
.allcp-form .switch-custom > input:checked:hover + label:after,
.allcp-form .switch-custom > input:checked:focus + label:after {
  background: #8b991e;
}
.allcp-form .button[disabled],
.allcp-form .state-disabled .button,
.allcp-form input[disabled] + .radio,
.allcp-form input[disabled] + .checkbox,
.allcp-form .switch > input[disabled] + label {
  cursor: default;
  opacity: 0.5;
}
.allcp-form .gui-input[disabled],
.allcp-form .gui-textarea[disabled],
.allcp-form .select > select[disabled],
.allcp-form .select-multiple select[disabled],
.allcp-form .gui-input[disabled] ~ .input-footer,
.allcp-form .file .gui-file[disabled] + .gui-input,
.allcp-form .file .gui-file[disabled]:hover + .gui-input,
.allcp-form .gui-textarea[disabled] ~ .input-footer {
  background-color: #f4f6f6;
  border-color: #d5dbdb!important;
  cursor: default;
  color: #d5dbdb;
  opacity: 0.7;
}
.allcp-form input[disabled] ~ .field-icon i,
.allcp-form textarea[disabled] ~ .field-icon i,
.allcp-form .select > select[disabled] + .arrow {
  opacity: 0.4;
}
.allcp-form .input-footer {
  padding: 10px;
  display: block;
  margin-top: -1px;
  line-height: 16px;
  position: relative;
  background: #F5F5F5;
  border: 1px solid #DDD;
  font-family: "GoogleSans", Helvetica, sans-serif;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  font-size: 11px;
  color: #999;
}
.allcp-form .append-icon,
.allcp-form .prepend-icon {
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
}
.allcp-form .append-icon .field-icon,
.allcp-form .prepend-icon .field-icon {
  top: 0;
  z-index: 4;
  width: 42px;
  height: 42px;
  color: inherit;
  line-height: 42px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  pointer-events: none;
}
.allcp-form .append-icon .field-icon i,
.allcp-form .prepend-icon .field-icon i {
  position: relative;
  font-size: 14px;
}
.allcp-form .prepend-icon .field-icon {
  left: 0;
}
.allcp-form .append-icon .field-icon {
  right: 0;
}
.allcp-form .prepend-icon > input,
.allcp-form .prepend-icon > textarea {
  padding-left: 36px;
}
.allcp-form .append-icon > input,
.allcp-form .append-icon > textarea {
  padding-right: 36px;
  padding-left: 10px;
}
.allcp-form .append-icon > textarea {
  padding-right: 36px;
}
@media (max-width: 359px) {
  .allcp-form .prepend-icon > .field-icon {
    display: none;
  }
  .allcp-form .prepend-icon > input,
  .allcp-form .prepend-icon > textarea {
    padding: 0 18px;
    text-align: center;
  }
}
.allcp-form .tooltip {
  position: absolute;
  z-index: -1;
  opacity: 0;
  color: #fff;
  width: 184px;
  left: -9999px;
  top: auto;
  font-size: 11px;
  font-weight: normal;
  background: #666;
  -webkit-transition: margin 0.6s, opacity 0.6s;
  -moz-transition: margin 0.6s, opacity 0.6s;
  -ms-transition: margin 0.6s, opacity 0.6s;
  -o-transition: margin 0.6s, opacity 0.6s;
  transition: margin 0.6s, opacity 0.6s;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.allcp-form .tooltip > em {
  padding: 12px;
  font-style: normal;
  display: block;
  position: static;
}
.allcp-form .tooltip:after {
  content: '';
  position: absolute;
}
.allcp-form .gui-input:focus + .tooltip,
.allcp-form .gui-textarea:focus + .tooltip {
  opacity: 1;
  z-index: 999;
}
.allcp-form .tip-left {
  top: 1px;
  margin-right: -20px;
}
.allcp-form .tip-left:after {
  top: 12px;
  left: 100%;
  border-left: 8px solid #666;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.allcp-form .gui-input:focus + .tip-left,
.allcp-form .gui-textarea:focus + .tip-left {
  margin-right: 5px;
  right: 100%;
  left: auto;
}
.allcp-form .tip-right {
  top: 1px;
  margin-left: -20px;
}
.allcp-form .tip-right:after {
  top: 12px;
  right: 100%;
  border-right: 8px solid #666;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.allcp-form .gui-input:focus + .tip-right,
.allcp-form .gui-textarea:focus + .tip-right {
  left: 100%;
  margin-left: 5px;
}
.allcp-form .tip-right-top {
  bottom: 100%;
  margin-bottom: -20px;
}
.allcp-form .tip-right-top:after {
  top: 100%;
  right: 12px;
  border-top: 8px solid #666;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.allcp-form .gui-input:focus + .tip-right-top,
.allcp-form .gui-textarea:focus + .tip-right-top {
  right: 0;
  left: auto;
  margin-bottom: 10px;
}
.allcp-form .tip-left-top {
  bottom: 100%;
  margin-bottom: -20px;
}
.allcp-form .tip-left-top:after {
  top: 100%;
  left: 12px;
  border-top: 8px solid #666;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.allcp-form .gui-input:focus + .tip-left-top,
.allcp-form .gui-textarea:focus + .tip-left-top {
  left: 0;
  right: auto;
  margin-bottom: 10px;
}
.allcp-form .tip-right-bottom {
  top: 100%;
  margin-top: -20px;
}
.allcp-form .tip-right-bottom:after {
  right: 12px;
  bottom: 100%;
  border-bottom: 8px solid #666;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.allcp-form .gui-input:focus + .tip-right-bottom,
.allcp-form .gui-textarea:focus + .tip-right-bottom {
  margin-top: 10px;
  left: auto;
  right: 0;
}
.allcp-form .tip-left-bottom {
  top: 100%;
  margin-top: -20px;
}
.allcp-form .tip-left-bottom:after {
  left: 12px;
  bottom: 100%;
  border-bottom: 8px solid #666;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.allcp-form .gui-input:focus + .tip-left-bottom,
.allcp-form .gui-textarea:focus + .tip-left-bottom {
  margin-top: 10px;
  right: auto;
  left: 0;
}
.allcp-form .state-error .gui-input,
.allcp-form .state-error .gui-textarea,
.allcp-form .state-error.select > select,
.allcp-form .state-error.select-multiple > select,
.allcp-form .state-error input:hover + .checkbox,
.allcp-form .state-error input:hover + .radio,
.allcp-form .state-error input:focus + .checkbox,
.allcp-form .state-error input:focus + .radio,
.allcp-form .state-error .checkbox,
.allcp-form .state-error .radio {
  background: #FEE9EA;
  border-color: #DE888A;
}
.allcp-form .state-error .gui-input:focus,
.allcp-form .state-error .gui-textarea:focus,
.allcp-form .state-error.select > select:focus,
.allcp-form .state-error.select-multiple > select:focus {
  -webkit-box-shadow: 0px 0px 3px #DE888A inset;
  -moz-box-shadow: 0px 0px 3px #DE888A inset;
  -o-box-shadow: 0px 0px 3px #DE888A inset;
  box-shadow: 0px 0px 3px #DE888A inset;
}
.allcp-form .state-error .gui-input ~ .field-icon i,
.allcp-form .state-error .gui-textarea ~ .field-icon i {
  color: #DE888A;
}
.allcp-form .state-error.select .arrow {
  color: #DE888A;
}
.allcp-form .state-error.select > select:focus + .arrow {
  color: #DE888A;
}
.allcp-form .state-error .gui-input ~ .input-footer,
.allcp-form .state-error.file .gui-file:hover + .gui-input,
.allcp-form .state-error .gui-textarea ~ .input-footer {
  border-color: #DE888A;
}
.allcp-form .state-error + em {
  display: block!important;
  margin-top: 6px;
  padding: 0 3px;
  font-family: "GoogleSans", Helvetica, sans-serif;
  font-style: normal;
  line-height: normal;
  font-size: 0.85em;
  color: #DE888A;
}
.allcp-form .state-success .gui-input,
.allcp-form .state-success .gui-textarea,
.allcp-form .state-success.select > select,
.allcp-form .state-success.select-multiple > select,
.allcp-form .state-success input:hover + .checkbox,
.allcp-form .state-success input:hover + .radio,
.allcp-form .state-success input:focus + .checkbox,
.allcp-form .state-success input:focus + .radio,
.allcp-form .state-success .checkbox,
.allcp-form .state-success .radio {
  background: #F0FEE9;
  border-color: #A5D491;
}
.allcp-form .state-success .gui-input:focus,
.allcp-form .state-success .gui-textarea:focus,
.allcp-form .state-success.select > select:focus,
.allcp-form .state-success.select-multiple > select:focus {
  -webkit-box-shadow: 0px 0px 3px #A5D491 inset;
  -moz-box-shadow: 0px 0px 3px #A5D491 inset;
  -o-box-shadow: 0px 0px 3px #A5D491 inset;
  box-shadow: 0px 0px 3px #A5D491 inset;
}
.allcp-form .state-success .gui-input ~ .field-icon i,
.allcp-form .state-success .gui-textarea ~ .field-icon i {
  color: #A5D491;
}
.allcp-form .state-success.select .arrow {
  color: #A5D491;
}
.allcp-form .state-success.select > select:focus + .arrow {
  color: #A5D491;
}
.allcp-form .state-success .gui-input ~ .input-footer,
.allcp-form .state-success.file .gui-file:hover + .gui-input,
.allcp-form .state-success .gui-textarea ~ .input-footer {
  border-color: #A5D491;
}
.allcp-form .smart-widget,
.allcp-form .append-picker-icon,
.allcp-form .prepend-picker-icon {
  position: relative;
  display: block;
}
.allcp-form .smart-widget .field input,
.allcp-form .append-picker-icon input,
.allcp-form .prepend-picker-icon input {
  width: 100%;
}
.allcp-form .sm-left input,
.allcp-form .prepend-picker-icon input {
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.allcp-form .sm-right input,
.allcp-form .append-picker-icon input {
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.allcp-form .append-picker-icon button,
.allcp-form .prepend-picker-icon button,
.allcp-form .smart-widget .button {
  cursor: pointer;
  position: absolute;
  top: 0;
  color: #626262;
  height: 42px;
  line-height: 40px;
  border: 1px solid #DDD;
  background: #F5F5F5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.allcp-form .prepend-picker-icon button:disabled {
  background-color: #f4f6f6 !important;
  border-color: #d5dbdb!important;
}
.allcp-form .sm-right .button,
.allcp-form .append-picker-icon button {
  border-left: 0;
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.allcp-form .sm-left .button,
.allcp-form .prepend-picker-icon button {
  border-right: 0;
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.allcp-form .sm-left .button,
.allcp-form .prepend-picker-icon button {
  left: 0;
}
.allcp-form .sm-right .button,
.allcp-form .append-picker-icon button {
  right: 0;
}
.allcp-form .sml-50,
.allcp-form .prepend-picker-icon {
  padding-left: 50px;
}
.allcp-form .sml-50 .button,
.allcp-form .prepend-picker-icon button {
  width: 50px;
}
.allcp-form .sml-80 {
  padding-left: 80px;
}
.allcp-form .sml-80 .button {
  width: 80px;
}
.allcp-form .sml-120 {
  padding-left: 120px;
}
.allcp-form .sml-120 .button {
  width: 120px;
}
.allcp-form .sml-160 {
  padding-left: 160px;
}
.allcp-form .sml-160 .button {
  width: 160px;
}
.allcp-form .smr-50,
.allcp-form .append-picker-icon {
  padding-right: 50px;
}
.allcp-form .smr-50 .button,
.allcp-form .append-picker-icon button {
  width: 50px;
}
.allcp-form .smr-80 {
  padding-right: 80px;
}
.allcp-form .smr-80 .button {
  width: 80px;
}
.allcp-form .smr-120 {
  padding-right: 120px;
}
.allcp-form .smr-120 .button {
  width: 120px;
}
.allcp-form .smr-140 {
  padding-right: 140px;
}
.allcp-form .smr-140 .button {
  width: 140px;
}
.allcp-form .smr-160 {
  padding-right: 160px;
}
.allcp-form .smr-160 .button {
  width: 160px;
}
.allcp-form .rating {
  overflow: hidden;
}
.allcp-form .rating.block {
  display: block;
  margin: 10px 0;
}
.allcp-form .rating label {
  color: #CCC;
}
.allcp-form .rating label i {
  font-size: 17px;
  text-align: center;
  color: inherit;
}
.allcp-form .rating label span {
  font: 22px/22px Times, Serif;
}
.allcp-form .rating-star {
  margin-left: 4px;
}
.allcp-form .rating-input {
  position: absolute;
  left: -9999px;
  top: auto;
}
.allcp-form .rating:hover .rating-star:hover,
.allcp-form .rating:hover .rating-star:hover ~ .rating-star,
.allcp-form .rating-input:checked ~ .rating-star {
  color: #666;
}
.allcp-form .rating-star,
.allcp-form .rating:hover .rating-star {
  width: 18px;
  float: right;
  display: block;
  cursor: pointer;
  color: #CCC;
}
.allcp-form .button {
  color: #243140;
  border: 0;
  height: 42px;
  line-height: 42px;
  font-size: 15px;
  cursor: pointer;
  padding: 0 18px;
  text-align: center;
  vertical-align: top;
  background: #DBDBDB;
  display: inline-block;
  -webkit-user-drag: none;
  text-shadow: 0 1px rgba(255, 255, 255, 0.2);
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.allcp-form .button:hover {
  color: #243140;
  background: #EEE;
}
.allcp-form .button:active {
  color: #1d2938;
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.allcp-form a.button,
.allcp-form span.button,
.allcp-form label.button {
  line-height: 42px;
  text-decoration: none;
}
.allcp-form .button i {
  font-size: 14px;
}
.allcp-form .button-list .button {
  margin-bottom: 5px;
}

.allcp-form .button-left,
.allcp-form .button-right {
  position: relative;
  z-index: 9;
}
.allcp-form .button-left:before,
.allcp-form .button-right:before {
  content: '';
  z-index: -1;
  width: 32px;
  height: 32px;
  position: absolute;
  background-color: inherit;
  border-color: inherit;
  border: none;
  top: 5px;
}
.allcp-form .button-left {
  border-left-width: 0;
  padding: 0 18px 0 7px;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -o-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  margin-left: 20px;
}
.allcp-form .button-left:before {
  left: -15px;
  -webkit-border-radius: 2px 5px 0 5px;
  -moz-border-radius: 2px 5px 0 5px;
  -o-border-radius: 2px 5px 0 5px;
  border-radius: 2px 5px 0 5px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.allcp-form .button-right {
  padding: 0 7px 0 18px;
  border-right-width: 0;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -o-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  margin-right: 20px;
}
.allcp-form .button-right:before {
  right: -15px;
  -webkit-border-radius: 5px 2px 5px 0;
  -moz-border-radius: 5px 2px 5px 0;
  -o-border-radius: 5px 2px 5px 0;
  border-radius: 5px 2px 5px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.allcp-form .pushed {
  -webkit-box-shadow: inset 0 -0.3em 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 -0.3em 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: inset 0 -0.3em 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -0.3em 0 rgba(0, 0, 0, 0.2);
  position: relative;
}
.allcp-form .pushed:active {
  -webkit-box-shadow: inset 0 -0.15em 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 -0.15em 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: inset 0 -0.15em 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -0.15em 0 rgba(0, 0, 0, 0.2);
  top: 2px;
}
.allcp-form .pushed.button-left:before {
  -webkit-box-shadow: inset 0.35em 0 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0.35em 0 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: inset 0.35em 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0.35em 0 0 rgba(0, 0, 0, 0.2);
}
.allcp-form .pushed:active.button-left:before {
  -webkit-box-shadow: inset 0.2em 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0.2em 0 0 rgba(0, 0, 0, 0.2);
}
.allcp-form .pushed.button-right:before {
  -webkit-box-shadow: inset -0.35em 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset -0.35em 0 0 rgba(0, 0, 0, 0.2);
}
.allcp-form .pushed:active.button-right:before {
  -webkit-box-shadow: inset -0.2em 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset -0.2em 0 0 rgba(0, 0, 0, 0.2);
}
.allcp-form .form-footer .button {
  margin-right: 10px;
  margin-bottom: 5px;
}
.allcp-form .align-right .button {
  margin-right: 0;
  margin-left: 10px;
}
.allcp-form .twitter,
.allcp-form .twitter:hover,
.allcp-form .twitter:focus,
.allcp-form .facebook,
.allcp-form .facebook:hover,
.allcp-form .facebook:focus,
.allcp-form .googleplus,
.allcp-form .googleplus:hover,
.allcp-form .googleplus:focus {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}
.allcp-form .facebook {
  background-color: #3b5998;
}
.allcp-form .twitter {
  background-color: #00acee;
}
.allcp-form .googleplus {
  background-color: #dd4b39;
}
.allcp-form .facebook:hover,
.allcp-form .facebook:focus {
  background-color: #25385F;
}
.allcp-form .twitter:hover,
.allcp-form .twitter:focus {
  background-color: #00749F;
}
.allcp-form .googleplus:hover,
.allcp-form .googleplus:focus {
  background-color: #8D2418;
}
.allcp-form .span-left {
  padding-left: 56px;
  text-align: left;
}
.allcp-form .btn-social {
  position: relative;
  font-weight: 600;
  margin-bottom: 5px;
}
.allcp-form .btn-social i {
  font-size: 22px;
  font-weight: normal;
  position: relative;
  top: 3px;
}
.allcp-form .btn-social span {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -o-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 45px;
  left: 0;
}
.allcp-form .twitter span {
  background-color: #009AD5;
}
.allcp-form .facebook span {
  background-color: #31497D;
}
.allcp-form .googleplus span {
  background-color: #C03121;
}
.allcp-form .allcp-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.allcp-form .allcp-list li {
  margin-bottom: 20px;
}
@-moz-document url-prefix() {
  .allcp-form .select:before {
    content: '';
    pointer-events: none;
    -moz-transition: none;
    transition: none;
    position: absolute;
    background: #F5F5F5;
    width: 36px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    z-index: 99;
  }
  .allcp-form .select > select:focus,
  .allcp-form .select > select:hover,
  .allcp-form .select:hover select,
  .allcp-form .select:hover:before {
    background: #fff;
    -moz-transition: none;
    transition: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .allcp-form .select .arrow {
    z-index: 100;
  }
  .allcp-form .state-error.select > select:focus,
  .allcp-form .state-error.select > select:hover,
  .allcp-form .state-error.select:hover select,
  .allcp-form .state-error.select:hover:before,
  .allcp-form .state-error.select:before {
    background: #FEE9EA;
  }
  .allcp-form .state-success.select > select:focus,
  .allcp-form .state-success.select > select:hover,
  .allcp-form .state-success.select:hover select,
  .allcp-form .state-success.select:hover:before,
  .allcp-form .state-success.select:before {
    background: #F0FEE9;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .allcp-form .select .arrow:after,
  .allcp-form .select .arrow:before {
    display: block;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .allcp-form .option,
  .allcp-form .rating,
  .allcp-form .switch,
  .allcp-form .captcode {
    -webkit-animation: bugfix infinite 1s;
  }
  @-webkit-keyframes bugfix {
    from {
      padding: 0;
    }
    to {
      padding: 0;
    }
  }
  .allcp-form .switch {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .allcp-form .option {
    margin-right: 15px;
  }
}
@media (max-width: 600px) {
  .allcp-form .section row {
    margin: 0;
  }
  .allcp-form .section row .colm {
    width: 100%;
    float: none;
    padding: 0;
  }
  .allcp-form .bdl {
    border-left: 0;
  }
  .allcp-form .border-right {
    border-right: 0;
  }
  .allcp-form .align-right {
    text-align: left;
  }
}
@media (max-width: 479px) {
  .allcp-form .select > select {
    padding-right: 30px;
  }
}
.allcp-form.theme-primary .gui-input,
.allcp-form.theme-primary .select > select,
.allcp-form.theme-primary .select-multiple select {
  outline: 1px solid transparent;
  -webkit-transition: outline-color 0.5s ease;
  transition: outline-color ease 0.5s;
}
.allcp-form.theme-primary .gui-input:focus,
.allcp-form.theme-primary .select > select:focus,
.allcp-form.theme-primary .select-multiple select:focus {
  outline-color: #469aa7;
}
.stepper-wrap {
  position: relative;
  display: block;
  font: 11px 'Arial', sans-serif;
}
.stepper-wrap input {
  text-align: right;
  padding-right: 20px;
}
.stepper-btn-wrap {
  position: absolute;
  top: 0;
  right: -34px;
  width: 34px;
  height: 100%;
  overflow: hidden;
  background: #F5F5F5;
  border: 1px solid #ccc;
  border-width: 1px 1px 1px 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.stepper-btn-wrap a {
  display: block;
  height: 50%;
  overflow: hidden;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  text-shadow: 1px 1px 0 #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-indent: -9999px;
  background: #F5F5F5;
  cursor: default;
  color: #666;
}
.stepper-btn-wrap a:hover {
  background: #F9F9F9;
}
.stepper-btn-up:before,
.stepper-btn-dwn:before {
  content: '';
  border: 4px dashed;
  pointer-events: none;
  border-color: #9F9F9F transparent;
  border-color: #9f9f9f rgba(255, 255, 255, 0);
  position: absolute;
  right: 14px;
  z-index: 1;
  height: 0;
  top: 32%;
  width: 0;
}
.stepper-btn-up:before {
  border-bottom-style: solid;
  border-top: none;
}
.stepper-btn-dwn:before {
  margin-top: 10px;
  border-top-style: solid;
  border-bottom: none;
}
.ui-datepicker-calendar > thead th {
  color: #9b9ead;
  font: 400 14px/30px 'Lato';
  padding-top: 10px;
  text-transform: uppercase;
}
.ui-datepicker {
  z-index: 1025 !important;
  display: none;
  position: relative;
  padding: 20px 30px 5px;
  margin-top: 8px;
  background: #fff;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #DDD;
  text-align: center;
  color: #666;
}
.ui-datepicker a {
  color: #404040;
  text-align: center;
}
.ui-datepicker .ui-state-disabled span {
  color: #DBDBDB;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
  background: #fff;
  border-bottom: 0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #9b9ead;
  font: 600 14px/27px 'Open Sans';
  padding: 5px 10px;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-year,
.ui-datepicker .ui-datepicker-header .ui-datepicker-month {
  color: #2a2f43;
  font: 600 20px/27px 'Lato';
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  width: 34px;
  height: 34px;
  display: block;
  font-size: 12px;
  position: absolute;
  text-decoration: none;
  cursor: pointer;
  color: #666;
}
.ui-datepicker .ui-datepicker-prev {
  left: 0;
}
.ui-datepicker .ui-datepicker-next {
  right: 0;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
  color: #9b9ead !important;
}
.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
  padding: 1px 5px;
  background-color: #EEE;
  border-color: #CCC;
}
.ui-datepicker .ui-datepicker-title select option {
  background: #FFF;
}
.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 52%;
}
.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  margin: 0 0 15px;
  border-collapse: collapse;
}
.ui-datepicker th {
  padding: .5em .3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}
.ui-datepicker td {
  border: 0;
  padding: 2px 5px;
}
.ui-datepicker td span,
.ui-datepicker td a {
  color: #AAA;
  padding: .35em .25em;
  display: block;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
}
.ui-datepicker td span:hover,
.ui-datepicker td a:hover {
  background: #F5F5F5;
}
.ui-datepicker .ui-state-disabled span:hover {
  background: none;
}
.ui-datepicker-today a,
.ui-datepicker-today a:hover,
.ui-datepicker .ui-state-active,
.ui-datepicker .ui-state-highlight {
  font-weight: 600;
  color: #fff;
  background: #CCC !important;
}
.ui-datepicker .ui-state-active {
  background: #888 !important;
}
@media (max-width: 479px) {
  .ui-datepicker .ui-datepicker-header .ui-datepicker-year,
  .ui-datepicker .ui-datepicker-header .ui-datepicker-month {
    font: 600 12px/16px 'Lato';
  }
  .ui-datepicker .ui-datepicker-title {
    line-height: 1.75em;
  }
  .ui-datepicker-calendar > thead th {
    font-size: 11px;
  }
}
.ui-datepicker-buttonpane {
  border-top: 0;
  padding: 20px;
  background: #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.ui-datepicker-buttonpane button {
  padding: 5px 28px;
  margin-right: 10px;
  font-size: 11px;
  position: relative;
  line-height: normal;
  display: inline-block;
  -webkit-user-drag: none;
  text-shadow: 0 1px rgba(255, 255, 255, 0.2);
  vertical-align: middle;
  background: #469aa7;
  height: 28px;
  text-align: center;
  overflow: visible;
  cursor: pointer;
  color: #fff;
  border: 0;
  border-bottom: 3px solid #26abbb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-weight: bold;
  font-family: 'GoogleSans';
  text-transform: uppercase;
}
.ui-datepicker-buttonpane button:hover {
  color: #fff;
  background: #26abbb;
}
.ui-datepicker-buttonpane button:active {
  color: #fff;
  background: #26abbb;
}
.ui-monthpicker .ui-datepicker-header {
  margin-bottom: 3px;
}
.ui-datepicker-inline {
  width: 100%;
}
.ui-datepicker-inline,
.ui-timepicker-inline,
.ui-datetimepicker-inline {
  box-shadow: none;
  margin: 0 auto;
}
.hasMonthpicker .ui-datepicker td {
  width: 25%;
  padding: 10px 8px;
}
@media (max-width: 479px) {
  .hasMonthpicker .ui-datepicker td {
    padding: 10px 5px;
  }
}
.cal-widget .ui-datepicker {
  width: 100%;
  margin-top: 0;
}
.cal-widget .ui-datepicker:before {
  display: none;
}
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.333%;
}
.ui-datepicker-multi-3 .ui-datepicker-group + .ui-datepicker-group {
  border-left: 1px solid #e5eaee;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}
.ui-timepicker-div {
  font-family: 'GoogleSans';
}
.ui-timepicker-div .ui-widget-header {
  background: #fff;
  border-bottom: 0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #a9acbd;
  font: 600 14px/27px 'Open Sans';
  padding: 5px 10px;
  position: relative;
}
.ui-timepicker-div dl {
  text-align: left;
  padding: 25px 0 5px;
  margin: 0;
}
.ui-timepicker-div dl dt {
  float: left;
  clear: left;
  padding: 0 0 0 5px;
}
.ui-timepicker-div dl dd {
  margin: 0 10px 20px 40%;
  text-align: right;
}
.ui-timepicker-div dl .ui_tpicker_hour {
  margin-bottom: 38px;
}
.ui-timepicker-div dl .ui_tpicker_hour_label {
  margin-bottom: 20px;
}
.ui-timepicker-div dl .ui_tpicker_hour,
.ui-timepicker-div dl .ui_tpicker_minute,
.ui-timepicker-div dl .ui_tpicker_second,
.ui-timepicker-div dl .ui_tpicker_millisec {
  background: #E5E5E5;
  position: relative;
  top: 10px;
}
.ui-timepicker-div td {
  font-size: 90%;
}
.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
.ui-timepicker-rtl {
  direction: rtl;
}
.ui-timepicker-rtl dl {
  text-align: right;
  padding: 0 5px 0 0;
}
.ui-timepicker-rtl dl dt {
  float: right;
  clear: right;
}
.ui-timepicker-rtl dl dd {
  margin: 0 40% 10px 10px;
}
.ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #FFF !important;
}
.allcp-form .slider-countbox {
  margin-bottom: 5px;
  color: #5a5386;
  font-weight: bold;
  border: 0;
  background: transparent;
}
.slider-wrapper,
.sliderv-wrapper {
  background: #E5E5E5;
  position: relative;
}
.ui-slider {
  position: relative;
  text-align: left;
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 17px;
  height: 17px;
  cursor: default;
  background: #fff;
  border: 2px solid #e5eaee !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -ms-touch-action: none;
  touch-action: none;
  margin-top: -3px;
  outline: none;
}
.ui-slider .ui-slider-handle:before {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: #ccc;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  z-index: 2;
  left: 3px;
  top: 3px;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0;
  background-color: #aaa;
}
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}
.ui-slider-horizontal {
  height: 2px;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -0.3em;
  margin-left: -0.6em;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}
.ui-slider-vertical,
.sliderv-wrapper {
  width: .5em;
  height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
  left: -0.45em;
  margin-left: 0;
  margin-bottom: -0.6em;
}
.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}
.slider-input {
  color: #f6931f!important;
  border: 0;
  background: none;
}
.slider-group .sliderv-wrapper {
  height: 150px;
  float: left;
  margin: 15px 15px;
}
.ui-slider .ui-state-active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
.ui-slider .ui-slider-range {
  background-size: 16px 16px;
  background-image: -webkit-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
  background-image: linear-gradient(to bottom right, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
}
.slider-tip {
  display: block;
  position: absolute;
  text-align: center;
  font: 10pt Tahoma, Arial, sans-serif;
  background: #333333;
  padding: 10px;
  color: #fff;
}
.slider-wrapper .slider-tip {
  top: -50px;
  left: -15px;
}
.slider-wrapper .slider-tip:after {
  content: '';
  position: absolute;
  top: 98%;
  left: 35%;
  border-top: 8px solid #333333;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.sliderv-wrapper .slider-tip {
  left: 30px;
  top: -12px;
}
.sliderv-wrapper .slider-tip:after {
  content: '';
  position: absolute;
  top: 30%;
  right: 98%;
  border-right: 8px solid #333333;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.sp-container {
  position: absolute;
  display: inline-block;
  *display: inline;
  z-index: 9999994;
  overflow: hidden;
  *zoom: 1;
  top: 0;
  left: 0;
  border-radius: 0;
  padding: 0;
  background: #f8f8f8;
  border: 1px solid #DDD;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
.sp-container.sp-left {
  top: 50px !important;
  left: auto !important;
  right: 0px !important;
}
.sp-container.sp-flat {
  position: relative;
}
.sp-top {
  position: relative;
  display: inline-block;
  width: 100%;
}
.sp-top-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.sp-color {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20%;
}
.sp-hue {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 84%;
  height: 100%;
}
.sp-clear-enabled .sp-hue {
  top: 33px;
  height: 77.5%;
}
.sp-fill {
  padding-top: 80%;
}
.sp-sat,
.sp-val {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sp-alpha-enabled .sp-top {
  margin-bottom: 18px;
}
.sp-alpha-enabled .sp-alpha {
  display: block;
}
.sp-alpha-handle {
  position: absolute;
  top: -4px;
  bottom: -4px;
  width: 6px;
  left: 50%;
  cursor: pointer;
  border: 1px solid black;
  background: white;
  opacity: .8;
}
.sp-alpha {
  display: none;
  position: absolute;
  bottom: -14px;
  right: 0;
  left: 0;
  height: 8px;
}
.sp-alpha-inner {
  border: solid 1px #333;
}
.sp-clear {
  display: none;
}
.sp-clear.sp-clear-display {
  background-position: center;
}
.sp-clear-enabled .sp-clear {
  display: block;
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 84%;
  height: 28px;
}
.sp-dd,
.sp-clear,
.sp-alpha,
.sp-slider,
.sp-dragger,
.sp-preview,
.sp-alpha-handle,
.sp-container,
.sp-replacer,
.sp-container.sp-dragging .sp-input,
.sp-container button {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.sp-container.sp-input-disabled .sp-input-container {
  display: none;
}
.sp-container.sp-buttons-disabled .sp-button-container {
  display: none;
}
.sp-palette-only .sp-picker-container {
  display: none;
}
.sp-palette-disabled .sp-palette-container {
  display: none;
}
.sp-initial-disabled .sp-initial {
  display: none;
}
.sp-sat {
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#ffffff), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to right, #ffffff, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#FFFFFFFF', endColorstr='#00CC9A81');
}
.sp-val {
  background-image: -webkit-gradient(linear, 0 100%, 0 0, from(#000000), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to top, #000000, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00CC9A81', endColorstr='#FF000000');
}
.sp-hue {
  background: -moz-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -ms-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -o-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
  background: -webkit-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}
.sp-1 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ffff00');
}
.sp-2 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00');
}
.sp-3 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff');
}
.sp-4 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff');
}
.sp-5 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff');
}
.sp-6 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='#ff0000');
}
.sp-hidden {
  display: none !important;
}
.sp-cf:before,
.sp-cf:after {
  content: "";
  display: table;
}
.sp-cf:after {
  clear: both;
}
.sp-cf {
  *zoom: 1;
}
.sp-dragger {
  border-radius: 5px;
  height: 5px;
  width: 5px;
  border: 1px solid #fff;
  background: #000;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.sp-slider {
  position: absolute;
  top: 0;
  cursor: pointer;
  height: 3px;
  left: -1px;
  right: -1px;
  border: 1px solid #000;
  background: white;
  opacity: .8;
}
.sp-container,
.sp-container button,
.sp-container input,
.sp-color,
.sp-hue,
.sp-clear {
  font: normal 12px "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.sp-top {
  margin-bottom: 3px;
}
.sp-color,
.sp-hue,
.sp-clear {
  border: solid 1px #666;
}
.sp-input-container {
  float: right;
  width: 100px;
  margin-bottom: 4px;
}
.sp-initial-disabled .sp-input-container {
  width: 100%;
}
.sp-input {
  font-size: 12px !important;
  border: 1px inset;
  padding: 4px 5px;
  background: transparent;
  border-radius: 3px;
  width: 100%;
  color: #222;
  margin: 0;
}
.sp-input:focus {
  border: 1px solid orange;
}
.sp-input.sp-validation-error {
  border: 1px solid red;
  background: #fdd;
}
.sp-picker-container,
.sp-palette-container {
  float: left;
  position: relative;
  padding: 10px 15px;
  padding-bottom: 300px;
  margin-bottom: -290px;
  min-height: 300px;
}
.sp-picker-container {
  width: 172px;
  border-left: 1px solid #DDD;
  background-color: #f1f1f1;
}
.sp-palette-container {
  padding: 5px;
  border-right: 1px solid transparent;
  background-color: #f1f1f1;
}
.sp-palette .sp-thumb-el {
  display: block;
  position: relative;
  float: left;
  width: 24px;
  height: 15px;
  margin: 3px;
  cursor: pointer;
  border: solid 2px transparent;
}
.sp-palette .sp-thumb-el:hover,
.sp-palette .sp-thumb-el.sp-thumb-active {
  border-color: orange;
}
.sp-thumb-el {
  position: relative;
}
.sp-initial {
  float: left;
  border: solid 1px #333;
}
.sp-initial span {
  width: 30px;
  height: 25px;
  border: none;
  display: block;
  float: left;
  margin: 0;
}
.sp-initial .sp-clear-display {
  background-position: center;
}
.sp-button-container {
  float: right;
}
.sp-replacer {
  cursor: pointer;
  overflow: hidden;
  padding: 4px 10px;
  position: absolute;
  background: #F5F5F5;
  vertical-align: middle;
  border: solid 1px #DDD;
  border-width: 1px 1px 1px 0;
  display: inline-block;
  *display: inline;
  height: 100%;
  color: #333;
  margin: 0;
  *zoom: 1;
  right: 0;
}
.sp-replacer:hover,
.sp-replacer.sp-active {
  color: #111;
  border-color: #CCC;
}
.sp-replacer.sp-disabled {
  cursor: default;
  border-color: #DDD;
  color: #AAA;
}
.sp-dd {
  padding: 2px 0;
  height: 16px;
  line-height: 16px;
  float: left;
  font-size: 12px;
  color: #9F9F9F;
  position: relative;
  top: 6px;
}
.sp-preview {
  position: relative;
  width: 25px;
  height: 20px;
  border: solid 1px #222;
  margin-right: 5px;
  margin-top: 6px;
  float: left;
  z-index: 0;
}
.sp-palette {
  *width: 220px;
  max-width: 220px;
}
.sp-palette .sp-thumb-el {
  width: 16px;
  height: 16px;
  margin: 2px 1px;
  border: solid 1px #d0d0d0;
}
.sp-container {
  padding-bottom: 0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.sp-container button {
  cursor: pointer;
  background-color: #469aa7;
  border: 0;
  border-bottom: 3px solid #26abbb;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  height: 28px;
  line-height: 1;
  padding: 7px 15px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: bold;
}
.sp-container button:hover {
  background-color: #26abbb;
}
.sp-container button:active {
  background-color: #26abbb;
}
.sp-cancel {
  font-size: 11px;
  color: #aaa !important;
  line-height: 28px;
  margin: 0;
  padding: 2px;
  margin-right: 7px;
  vertical-align: middle;
  text-decoration: none;
}
.sp-cancel:hover {
  color: #666 !important;
  text-decoration: none;
}
.sp-palette span:hover,
.sp-palette span.sp-thumb-active {
  border-color: #000;
}
.sp-preview,
.sp-alpha,
.sp-thumb-el {
  position: relative;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
}
.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.sp-palette .sp-thumb-inner {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.sp-palette .sp-thumb-light.sp-thumb-active .sp-thumb-inner {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIVJREFUeNpiYBhsgJFMffxAXABlN5JruT4Q3wfi/0DsT64h8UD8HmpIPCWG/KemIfOJCUB+Aoacx6EGBZyHBqI+WsDCwuQ9mhxeg2A210Ntfo8klk9sOMijaURm7yc1UP2RNCMbKE9ODK1HM6iegYLkfx8pligC9lCD7KmRof0ZhjQACDAAceovrtpVBRkAAAAASUVORK5CYII=);
}
.sp-palette .sp-thumb-dark.sp-thumb-active .sp-thumb-inner {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAMdJREFUOE+tkgsNwzAMRMugEAahEAahEAZhEAqlEAZhEAohEAYh81X2dIm8fKpEspLGvudPOsUYpxE2BIJCroJmEW9qJ+MKaBFhEMNabSy9oIcIPwrB+afvAUFoK4H0tMaQ3XtlrggDhOVVMuT4E5MMG0FBbCEYzjYT7OxLEvIHQLY2zWwQ3D+9luyOQTfKDiFD3iUIfPk8VqrKjgAiSfGFPecrg6HN6m/iBcwiDAo7WiBeawa+Kwh7tZoSCGLMqwlSAzVDhoK+6vH4G0P5wdkAAAAASUVORK5CYII=);
}
.sp-clear-display {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(data:image/gif;base64,R0lGODlhFAAUAPcAAAAAAJmZmZ2dnZ6enqKioqOjo6SkpKWlpaampqenp6ioqKmpqaqqqqurq/Hx8fLy8vT09PX19ff39/j4+Pn5+fr6+vv7+wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAUABQAAAihAP9FoPCvoMGDBy08+EdhQAIJCCMybCDAAYUEARBAlFiQQoMABQhKUJBxY0SPICEYHBnggEmDKAuoPMjS5cGYMxHW3IiT478JJA8M/CjTZ0GgLRekNGpwAsYABHIypcAgQMsITDtWJYBR6NSqMico9cqR6tKfY7GeBCuVwlipDNmefAtTrkSzB1RaIAoXodsABiZAEFB06gIBWC1mLVgBa0AAOw==);
}
.sfcolor {
  padding-right: 63px;
}
.sfcolor .gui-input {
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.sfcolor .gui-input + .sp-replacer {
  width: 63px;
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 479px) {
  .sp-container button {
    font-size: 10px;
    padding: 7px 10px;
  }
  .sp-input {
    font-size: 9px !important;
  }
}
.sp-preview {
  border-color: #fff;
}
.sp-picker-container {
  background-color: #fff;
  margin-bottom: -300px;
}
.sp-button-container {
  float: none;
  text-align: right;
  background: #fff;
  border-top: 0;
  margin-top: 35px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 6px;
}
.sp-palette-disabled .sp-button-container {
  margin-top: 5px;
}
.sp-dragger {
  background: rgba(255, 255, 255, 0.4);
  height: 10px;
  width: 10px;
  border-radius: 0px;
  border-color: rgba(255, 255, 255, 0.7);
}
.sp-color,
.sp-hue,
.sp-clear {
  border-color: #bbb;
}
.sp-input {
  background: #f1f1f1;
  border: 0;
}
.sp-slider {
  height: 6px;
  border: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.sp-palette-button-container {
  display: none;
}
.sp-palette .sp-thumb-el {
  width: 18px;
  height: 18px;
  margin: 2px;
  border-color: #fff;
}
.sp-palette .sp-thumb-el:hover,
.sp-palette .sp-thumb-el.sp-thumb-active {
  border-color: #fff;
}
.sp-palette .sp-thumb-light.sp-thumb-active .sp-thumb-inner {
  background-image: none;
}
.sp-palette .sp-thumb-light.sp-thumb-active .sp-thumb-inner:after {
  position: absolute;
  content: "\f00c";
  font-family: "FontAwesome";
  font-size: 12px;
  color: #FFF;
  left: 2px;
  top: 2px;
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
}
.inline-cp + .sp-container {
  width: 100%;
  box-shadow: none;
}
.inline-cp + .sp-container > .sp-picker-container {
  width: 70%;
}
.inline-cp + .sp-container > .sp-palette-container {
  width: 30%;
}
.inline-cp + .sp-container > .sp-palette-container .sp-palette .sp-thumb-el {
  width: 22px;
  height: 22px;
  margin: 3px;
}
.inline-cp + .sp-container > .sp-palette-container .sp-palette .sp-thumb-light.sp-thumb-active .sp-thumb-inner:after {
  font-size: 13px;
  left: 3px;
  top: 3px;
}
@media (max-width: 479px) {
  .inline-cp + .sp-container > .sp-palette-container .sp-palette .sp-thumb-el {
    margin: 4px 3px 5px;
  }
}
.allcp-form .map-container {
  padding: 7px;
  border: 1px solid #CFCFCF;
}
.allcp-form #map_canvas {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.allcp-form .block {
  display: block;
}
.wizard {
  background-color: #FFF;
}
.wizard .steps {
  -webkit-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  text-align: center;
  padding: 20px 15px 10px;
  border: 1px solid #E8E8E8;
  border-bottom: 0;
}
.wizard .steps ul,
.wizard .steps li {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}
.wizard .steps li {
  display: inline-block;
  margin-right: 10px;
}
.wizard .steps li.last {
  margin-right: 0;
}
.wizard .steps li.disabled a {
  cursor: not-allowed;
}
.wizard .steps li a {
  display: inline-block;
  color: #FFF;
  font-size: 15px;
  text-align: center;
  padding: 12px 18px;
  background-color: #80dae5;
  text-decoration: none;
  -webkit-user-drag: none;
}
.wizard .steps li.current a {
  background-color: #469aa7;
}
.wizard .steps li.disabled a {
  background-color: #BBBBBB !important;
}
.wizard .steps li.current a:focus {
  outline-color: transparent;
}
.wizard .steps li a .current-info,
.wizard .steps li a .number {
  display: none;
}
.wizard .content {
  background-color: #ffffff;
  border: 1px solid #E8E8E8;
  border-top: 0;
}
.wizard .content + .actions {
  border-top: 0;
}
.wizard .wizard-section-title {
  display: none;
}
.wizard .wizard-section.body {
  padding: 15px;
}
.wizard .actions {
  -webkit-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  padding: 10px 15px;
  background-color: #f8f8f8;
  border: 1px solid #E8E8E8;
}
.wizard .actions ul {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
  float: right;
}
.wizard .actions li {
  display: inline-block;
  margin-left: 10px;
}
.wizard .actions li a {
  display: inline-block;
  color: #FFF;
  padding: 9px 18px;
  background-color: #469aa7;
  text-decoration: none;
  -webkit-user-drag: none;
}
.wizard .actions li.disabled a {
  cursor: not-allowed;
  background-color: #BBB !important;
}
.wizard.steps-hide-icons .steps .fa,
.wizard.steps-hide-icons .steps .glyphicon,
.wizard.steps-hide-icons .steps .glyphicon {
  display: none !important;
}
.wizard.steps-show-icons .steps .fa,
.wizard.steps-show-icons .steps .glyphicon,
.wizard.steps-show-icons .steps .glyphicon {
  display: inline-block !important;
}
.wizard.steps-left .steps {
  text-align: left;
}
.wizard.steps-right .steps {
  text-align: right;
}
.wizard.steps-justified .steps {
  position: relative;
  width: 100%;
}
.wizard.steps-justified .steps li {
  display: table-cell;
  position: relative;
  float: none;
  width: 1%;
}
.wizard.steps-justified .steps li a {
  display: block;
}
.wizard.steps-tabs .steps {
  padding: 0;
  height: 55px;
  position: relative;
  background-color: #EEE;
  border-color: transparent;
  border-bottom: 1px solid #E8E8E8;
}
.wizard.steps-tabs .steps li {
  margin: 0;
}
.wizard.steps-tabs .steps li a {
  padding: 18px 23px 18px;
}
.wizard.steps-bg .steps {
  padding: 15px;
  position: relative;
  background-color: #f8f8f8;
  border-bottom: 1px solid #E8E8E8;
}
.wizard.steps-bg.steps-tabs .steps {
  padding: 0;
  border-color: #E8E8E8;
}
.wizard.steps-bg .content,
.wizard.steps-tabs .content,
.wizard.steps-justified .content {
  padding-top: 10px;
}
.allcp-form .panel-heading {
  color: #384B5F;
}
.allcp-form .panel-heading hr {
  margin: 20px 0px;
}
.allcp-form .heading-border:before {
  content: "";
  background-color: #9999A3;
  position: absolute;
  height: 10px;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
}
.allcp-form .heading-border .panel-heading {
  padding: 30px 22px 17px;
}
.allcp-form .heading-border .panel-title {
  color: #999;
}
.allcp-form .panel-title {
  text-align: left;
  font-weight: 300;
  font-size: 26px;
  padding: 0;
  margin: 0;
  background: transparent;
}
.allcp-form .panel-title i {
  font-size: 26px;
  position: relative;
  margin-right: 15px;
  top: 0;
  border-width: 0;
}

.allcp-form .panel-footer {
  padding: 12px 13px;
  border: 0;
  border-top: 1px solid #DDD;
  background: #f2f2f2;
}
.allcp-form .progress-section {
  display: none;
}
.progress-striped .progress-bar,
.progress-bar-striped {
  background-size: 18px 18px;
  background-image: -webkit-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
  background-image: -moz-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
  background-image: -o-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
  background-image: linear-gradient(to bottom right, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
}
.allcp-form .radio,
.allcp-form .checkbox {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  top: 1px;
  vertical-align: bottom;
}
.allcp-form .checkbox:after,
.allcp-form .checkbox:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box;
}
.allcp-form .bg-alert {
  background-color: #ffbc0b;
}
.allcp-form .bg-alert.light {
  background-color: #ac92ec;
}
.allcp-form .bg-alert.lighter {
  background-color: #c0b0ea;
}
.allcp-form .bg-alert.dark {
  background-color: #815fd5;
}
.allcp-form .bg-alert.darker {
  background-color: #6c44ce;
}
.allcp-form .panel-alert > .panel-heading {
  border-top-color: #ac92ec;
}
.allcp-form .panel-alert > .panel-heading:before {
  background-color: #ffbc0b;
}
.allcp-form .panel-alert.heading-border:before,
.allcp-form .panel-alert .heading-border:before {
  background-color: #ffbc0b;
}
.allcp-form .option-alert input:hover + .checkbox,
.allcp-form .option-alert input:hover + .radio {
  border-color: #ac92ec;
}
.allcp-form .option-alert input:checked + .checkbox,
.allcp-form .option-alert input:focus + .checkbox,
.allcp-form .option-alert input:checked + .radio,
.allcp-form .option-alert input:focus + .radio {
  border-color: #ffbc0b;
}
.allcp-form .option-alert input:checked + .radio:before,
.allcp-form .option-alert input:focus + .radio:before {
  background: #ffbc0b;
}
.allcp-form .option-alert input:checked + .checkbox:before,
.allcp-form .option-alert input:focus + .checkbox:before {
  border-color: #ffbc0b;
}
.allcp-form .btn-alert {
  background-color: #ffbc0b;
}
.allcp-form .btn-alert:hover,
.allcp-form .btn-alert:focus {
  background-color: #ac92ec;
  border-color: #ac92ec;
}
.allcp-form .btn-alert:active {
  background-color: #815fd5;
}
.allcp-form .btn-alert,
.allcp-form .btn-alert:hover,
.allcp-form .btn-alert:focus,
.allcp-form .btn-alert:active {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}
.allcp-form .switch-alert > input:checked + label {
  background: transparent;
  border-color: #ffbc0b;
  color: #ffbc0b;
}
.allcp-form .switch-alert > input:checked + label:after {
  background: #ffbc0b;
  color: #ffbc0b;
}
.allcp-form .switch-alert > input:checked:focus + label {
  background: #fff;
  border-color: #ffbc0b;
}
.allcp-form .slider-wrapper.slider-alert .ui-slider .ui-slider-range {
  background-color: #ffbc0b;
}
.allcp-form .slider-wrapper.slider-alert .ui-slider .ui-slider-handle {
  border-color: #ffbc0b;
}
.allcp-form .slider-wrapper.slider-alert .ui-slider .ui-slider-handle:before {
  background-color: #ffbc0b;
}
.allcp-form.theme-alert .tagline span {
  color: #ffbc0b;
}
.allcp-form.theme-alert .theme-link {
  color: #ffbc0b;
}
.allcp-form.theme-alert .section-divider span {
  color: #ffbc0b;
}
.allcp-form.theme-alert .gui-input:hover,
.allcp-form.theme-alert .gui-textarea:hover,
.allcp-form.theme-alert .select > select:hover,
.allcp-form.theme-alert .select-multiple select:hover,
.allcp-form.theme-alert .gui-input:hover ~ .input-footer,
.allcp-form.theme-alert .file .gui-file:hover + .gui-input,
.allcp-form.theme-alert .gui-textarea:hover ~ .input-footer {
  border-color: #ac92ec;
}
.allcp-form.theme-alert .gui-input:focus,
.allcp-form.theme-alert .gui-textarea:focus,
.allcp-form.theme-alert .select > select:focus,
.allcp-form.theme-alert .select-multiple select:focus {
  border-color: #ac92ec;
  color: #3C3C3C;
}
.allcp-form.theme-alert .gui-textarea:focus {
  height: 120px;
}
.allcp-form.theme-alert .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form.theme-alert .gui-input:focus ~ .field-icon i,
.allcp-form.theme-alert .gui-textarea:focus ~ .field-icon i {
  color: #ffbc0b;
}
.allcp-form.theme-alert .select-multiple select:focus,
.allcp-form.theme-alert .gui-input:focus ~ .input-footer,
.allcp-form.theme-alert .gui-textarea:focus ~ .input-footer,
.allcp-form.theme-alert .file .gui-file:focus + .gui-input {
  border-color: #ac92ec;
}
.allcp-form.theme-alert .select > select:focus + .arrow {
  color: #ffbc0b;
}
.allcp-form.theme-alert .ui-datepicker .ui-datepicker-prev,
.allcp-form.theme-alert .ui-datepicker .ui-datepicker-next,
.allcp-form.theme-alert .ui-datepicker .ui-datepicker-title {
  color: #ffbc0b;
}
.allcp-form.theme-alert .ui-datepicker-today a,
.allcp-form.theme-alert .ui-datepicker-calendar a:hover,
.allcp-form.theme-alert .ui-datepicker-today a:hover,
.allcp-form.theme-alert .ui-datepicker .ui-state-highlight {
  background-color: #ffffff !important;
  color: #ffbc0b !important;
}
.allcp-form.theme-alert .ui-datepicker .ui-state-active {
  background-color: #ffffff !important;
  color: #ffbc0b !important;
}
.allcp-form.theme-alert .rating:hover .rating-star:hover,
.allcp-form.theme-alert .rating:hover .rating-star:hover ~ .rating-star,
.allcp-form.theme-alert .rating-input:checked ~ .rating-star {
  color: #469aa7;
}
.allcp-form.theme-alert .rating-star,
.allcp-form.theme-alert .rating:hover .rating-star {
  color: #b9bbc5;
}
.allcp-form.theme-alert .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #FFF !important;
}
.allcp-form.theme-alert .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #ffbc0b;
}
.allcp-form.theme-alert .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #ffbc0b;
}
.allcp-form.theme-alert .wizard .steps li a,
.allcp-form.theme-alert .wizard .actions li a {
  background-color: #ffbc0b;
}
.allcp-form.theme-alert .wizard .steps li.current a {
  background-color: #815fd5;
}
.allcp-form .bg-danger {
  background-color: #f5393d;
}
.allcp-form .bg-danger.light {
  background-color: #fc6e51;
}
.allcp-form .bg-danger.lighter {
  background-color: #f08c7c;
}
.allcp-form .bg-danger.dark {
  background-color: #e63c21;
}
.allcp-form .bg-danger.darker {
  background-color: #cd3117;
}
.allcp-form .panel-danger > .panel-heading {
  border-top-color: #fc6e51;
}
.allcp-form .panel-danger > .panel-heading:before {
  background-color: #f5393d;
}
.allcp-form .panel-danger.heading-border:before,
.allcp-form .panel-danger .heading-border:before {
  background-color: #f5393d;
}
.allcp-form .option-danger input:hover + .checkbox,
.allcp-form .option-danger input:hover + .radio {
  border-color: #fc6e51;
}
.allcp-form .option-danger input:checked + .checkbox,
.allcp-form .option-danger input:focus + .checkbox,
.allcp-form .option-danger input:checked + .radio,
.allcp-form .option-danger input:focus + .radio {
  border-color: #f5393d;
}
.allcp-form .option-danger input:checked + .radio:before,
.allcp-form .option-danger input:focus + .radio:before {
  background: #f5393d;
}
.allcp-form .option-danger input:checked + .checkbox:before,
.allcp-form .option-danger input:focus + .checkbox:before {
  border-color: #f5393d;
}
.allcp-form .btn-danger {
  background-color: #f5393d;
}
.allcp-form .btn-danger:hover,
.allcp-form .btn-danger:focus {
  background-color: #fc6e51;
  border-color: #fc6e51;
}
.allcp-form .btn-danger:active {
  background-color: #e63c21;
}
.allcp-form .btn-danger,
.allcp-form .btn-danger:hover,
.allcp-form .btn-danger:focus,
.allcp-form .btn-danger:active {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}
.allcp-form .switch-danger > input:checked + label {
  background: transparent;
  border-color: #f5393d;
  color: #f5393d;
}
.allcp-form .switch-danger > input:checked + label:after {
  background: #f5393d;
  color: #f5393d;
}
.allcp-form .switch-danger > input:checked:focus + label {
  background: #fff;
  border-color: #f5393d;
}
.allcp-form .slider-wrapper.slider-danger .ui-slider .ui-slider-range {
  background-color: #f5393d;
}
.allcp-form .slider-wrapper.slider-danger .ui-slider .ui-slider-handle {
  border-color: #f5393d;
}
.allcp-form .slider-wrapper.slider-danger .ui-slider .ui-slider-handle:before {
  background-color: #f5393d;
}
.allcp-form.theme-danger .tagline span {
  color: #f5393d;
}
.allcp-form.theme-danger .theme-link {
  color: #f5393d;
}
.allcp-form.theme-danger .section-divider span {
  color: #f5393d;
}
.allcp-form.theme-danger .gui-input:hover,
.allcp-form.theme-danger .gui-textarea:hover,
.allcp-form.theme-danger .select > select:hover,
.allcp-form.theme-danger .select-multiple select:hover,
.allcp-form.theme-danger .gui-input:hover ~ .input-footer,
.allcp-form.theme-danger .file .gui-file:hover + .gui-input,
.allcp-form.theme-danger .gui-textarea:hover ~ .input-footer {
  border-color: #fc6e51;
}
.allcp-form.theme-danger .gui-input:focus,
.allcp-form.theme-danger .gui-textarea:focus,
.allcp-form.theme-danger .select > select:focus,
.allcp-form.theme-danger .select-multiple select:focus {
  border-color: #fc6e51;
  color: #3C3C3C;
}
.allcp-form.theme-danger .gui-textarea:focus {
  height: 120px;
}
.allcp-form.theme-danger .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form.theme-danger .gui-input:focus ~ .field-icon i,
.allcp-form.theme-danger .gui-textarea:focus ~ .field-icon i {
  color: #f5393d;
}
.allcp-form.theme-danger .select-multiple select:focus,
.allcp-form.theme-danger .gui-input:focus ~ .input-footer,
.allcp-form.theme-danger .gui-textarea:focus ~ .input-footer,
.allcp-form.theme-danger .file .gui-file:focus + .gui-input {
  border-color: #fc6e51;
}
.allcp-form.theme-danger .select > select:focus + .arrow {
  color: #f5393d;
}
.allcp-form.theme-danger .ui-datepicker .ui-datepicker-prev,
.allcp-form.theme-danger .ui-datepicker .ui-datepicker-next,
.allcp-form.theme-danger .ui-datepicker .ui-datepicker-title {
  color: #f5393d;
}
.allcp-form.theme-danger .ui-datepicker-today a,
.allcp-form.theme-danger .ui-datepicker-calendar a:hover,
.allcp-form.theme-danger .ui-datepicker-today a:hover,
.allcp-form.theme-danger .ui-datepicker .ui-state-highlight {
  background-color: #ffffff !important;
  color: #f5393d !important;
}
.allcp-form.theme-danger .ui-datepicker .ui-state-active {
  background-color: #ffffff !important;
  color: #f5393d !important;
}
.allcp-form.theme-danger .rating:hover .rating-star:hover,
.allcp-form.theme-danger .rating:hover .rating-star:hover ~ .rating-star,
.allcp-form.theme-danger .rating-input:checked ~ .rating-star {
  color: #469aa7;
}
.allcp-form.theme-danger .rating-star,
.allcp-form.theme-danger .rating:hover .rating-star {
  color: #b9bbc5;
}
.allcp-form.theme-danger .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #FFF !important;
}
.allcp-form.theme-danger .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #f5393d;
}
.allcp-form.theme-danger .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #f5393d;
}
.allcp-form.theme-danger .wizard .steps li a,
.allcp-form.theme-danger .wizard .actions li a {
  background-color: #f5393d;
}
.allcp-form.theme-danger .wizard .steps li.current a {
  background-color: #e63c21;
}
.allcp-form .bg-dark {
  background-color: #2a2f43;
}
.allcp-form .bg-dark.light {
  background-color: #424759;
}
.allcp-form .bg-dark.lighter {
  background-color: #51566c;
}
.allcp-form .bg-dark.dark {
  background-color: #222636;
}
.allcp-form .bg-dark.darker {
  background-color: #1e2028;
}
.allcp-form .panel-dark > .panel-heading {
  border-top-color: #424759;
}
.allcp-form .panel-dark > .panel-heading:before {
  background-color: #2a2f43;
}
.allcp-form .panel-dark.heading-border:before,
.allcp-form .panel-dark .heading-border:before {
  background-color: #2a2f43;
}
.allcp-form .option-dark input:hover + .checkbox,
.allcp-form .option-dark input:hover + .radio {
  border-color: #424759;
}
.allcp-form .option-dark input:checked + .checkbox,
.allcp-form .option-dark input:focus + .checkbox,
.allcp-form .option-dark input:checked + .radio,
.allcp-form .option-dark input:focus + .radio {
  border-color: #2a2f43;
}
.allcp-form .option-dark input:checked + .radio:before,
.allcp-form .option-dark input:focus + .radio:before {
  background: #2a2f43;
}
.allcp-form .option-dark input:checked + .checkbox:before,
.allcp-form .option-dark input:focus + .checkbox:before {
  border-color: #2a2f43;
}
.allcp-form .btn-dark {
  background-color: #2a2f43;
}
.allcp-form .btn-dark:hover,
.allcp-form .btn-dark:focus {
  background-color: #424759;
  border-color: #424759;
}
.allcp-form .btn-dark:active {
  background-color: #222636;
}
.allcp-form .btn-dark,
.allcp-form .btn-dark:hover,
.allcp-form .btn-dark:focus,
.allcp-form .btn-dark:active {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}
.allcp-form .switch-dark > input:checked + label {
  background: transparent;
  border-color: #2a2f43;
  color: #2a2f43;
}
.allcp-form .switch-dark > input:checked + label:after {
  background: #2a2f43;
  color: #2a2f43;
}
.allcp-form .switch-dark > input:checked:focus + label {
  background: #fff;
  border-color: #2a2f43;
}
.allcp-form .slider-wrapper.slider-dark .ui-slider .ui-slider-range {
  background-color: #2a2f43;
}
.allcp-form .slider-wrapper.slider-dark .ui-slider .ui-slider-handle {
  border-color: #2a2f43;
}
.allcp-form .slider-wrapper.slider-dark .ui-slider .ui-slider-handle:before {
  background-color: #2a2f43;
}
.allcp-form.theme-dark .tagline span {
  color: #2a2f43;
}
.allcp-form.theme-dark .theme-link {
  color: #2a2f43;
}
.allcp-form.theme-dark .section-divider span {
  color: #2a2f43;
}
.allcp-form.theme-dark .gui-input:hover,
.allcp-form.theme-dark .gui-textarea:hover,
.allcp-form.theme-dark .select > select:hover,
.allcp-form.theme-dark .select-multiple select:hover,
.allcp-form.theme-dark .gui-input:hover ~ .input-footer,
.allcp-form.theme-dark .file .gui-file:hover + .gui-input,
.allcp-form.theme-dark .gui-textarea:hover ~ .input-footer {
  border-color: #424759;
}
.allcp-form.theme-dark .gui-input:focus,
.allcp-form.theme-dark .gui-textarea:focus,
.allcp-form.theme-dark .select > select:focus,
.allcp-form.theme-dark .select-multiple select:focus {
  border-color: #424759;
  color: #3C3C3C;
}
.allcp-form.theme-dark .gui-textarea:focus {
  height: 120px;
}
.allcp-form.theme-dark .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form.theme-dark .gui-input:focus ~ .field-icon i,
.allcp-form.theme-dark .gui-textarea:focus ~ .field-icon i {
  color: #2a2f43;
}
.allcp-form.theme-dark .select-multiple select:focus,
.allcp-form.theme-dark .gui-input:focus ~ .input-footer,
.allcp-form.theme-dark .gui-textarea:focus ~ .input-footer,
.allcp-form.theme-dark .file .gui-file:focus + .gui-input {
  border-color: #424759;
}
.allcp-form.theme-dark .select > select:focus + .arrow {
  color: #2a2f43;
}
.allcp-form.theme-dark .ui-datepicker .ui-datepicker-prev,
.allcp-form.theme-dark .ui-datepicker .ui-datepicker-next,
.allcp-form.theme-dark .ui-datepicker .ui-datepicker-title {
  color: #2a2f43;
}
.allcp-form.theme-dark .ui-datepicker-today a,
.allcp-form.theme-dark .ui-datepicker-calendar a:hover,
.allcp-form.theme-dark .ui-datepicker-today a:hover,
.allcp-form.theme-dark .ui-datepicker .ui-state-highlight {
  background-color: #ffffff !important;
  color: #2a2f43 !important;
}
.allcp-form.theme-dark .ui-datepicker .ui-state-active {
  background-color: #ffffff !important;
  color: #2a2f43 !important;
}
.allcp-form.theme-dark .rating:hover .rating-star:hover,
.allcp-form.theme-dark .rating:hover .rating-star:hover ~ .rating-star,
.allcp-form.theme-dark .rating-input:checked ~ .rating-star {
  color: #469aa7;
}
.allcp-form.theme-dark .rating-star,
.allcp-form.theme-dark .rating:hover .rating-star {
  color: #b9bbc5;
}
.allcp-form.theme-dark .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #FFF !important;
}
.allcp-form.theme-dark .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #2a2f43;
}
.allcp-form.theme-dark .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #2a2f43;
}
.allcp-form.theme-dark .wizard .steps li a,
.allcp-form.theme-dark .wizard .actions li a {
  background-color: #2a2f43;
}
.allcp-form.theme-dark .wizard .steps li.current a {
  background-color: #222636;
}
.allcp-form .bg-info {
  background-color: #4fd8b0;
}
.allcp-form .bg-info.light {
  background-color: #4fc1e9;
}
.allcp-form .bg-info.lighter {
  background-color: #74c6e5;
}
.allcp-form .bg-info.dark {
  background-color: #27a0cc;
}
.allcp-form .bg-info.darker {
  background-color: #2189b0;
}
.allcp-form .panel-info > .panel-heading {
  border-top-color: #4fc1e9;
}
.allcp-form .panel-info > .panel-heading:before {
  background-color: #4fd8b0;
}
.allcp-form .panel-info.heading-border:before,
.allcp-form .panel-info .heading-border:before {
  background-color: #4fd8b0;
}
.allcp-form .option-info input:hover + .checkbox,
.allcp-form .option-info input:hover + .radio {
  border-color: #4fc1e9;
}
.allcp-form .option-info input:checked + .checkbox,
.allcp-form .option-info input:focus + .checkbox,
.allcp-form .option-info input:checked + .radio,
.allcp-form .option-info input:focus + .radio {
  border-color: #4fd8b0;
}
.allcp-form .option-info input:checked + .radio:before,
.allcp-form .option-info input:focus + .radio:before {
  background: #4fd8b0;
}
.allcp-form .option-info input:checked + .checkbox:before,
.allcp-form .option-info input:focus + .checkbox:before {
  border-color: #4fd8b0;
}
.allcp-form .btn-info {
  background-color: #4fd8b0;
}
.allcp-form .btn-info:hover,
.allcp-form .btn-info:focus {
  background-color: #4fc1e9;
  border-color: #4fc1e9;
}
.allcp-form .btn-info:active {
  background-color: #27a0cc;
}
.allcp-form .btn-info,
.allcp-form .btn-info:hover,
.allcp-form .btn-info:focus,
.allcp-form .btn-info:active {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}
.allcp-form .switch-info > input:checked + label {
  background: transparent;
  border-color: #4fd8b0;
  color: #4fd8b0;
}
.allcp-form .switch-info > input:checked + label:after {
  background: #4fd8b0;
  color: #4fd8b0;
}
.allcp-form .switch-info > input:checked:focus + label {
  background: #fff;
  border-color: #4fd8b0;
}
.allcp-form .slider-wrapper.slider-info .ui-slider .ui-slider-range {
  background-color: #4fd8b0;
}
.allcp-form .slider-wrapper.slider-info .ui-slider .ui-slider-handle {
  border-color: #4fd8b0;
}
.allcp-form .slider-wrapper.slider-info .ui-slider .ui-slider-handle:before {
  background-color: #4fd8b0;
}
.allcp-form.theme-info .tagline span {
  color: #4fd8b0;
}
.allcp-form.theme-info .theme-link {
  color: #4fd8b0;
}
.allcp-form.theme-info .section-divider span {
  color: #4fd8b0;
}
.allcp-form.theme-info .gui-input:hover,
.allcp-form.theme-info .gui-textarea:hover,
.allcp-form.theme-info .select > select:hover,
.allcp-form.theme-info .select-multiple select:hover,
.allcp-form.theme-info .gui-input:hover ~ .input-footer,
.allcp-form.theme-info .file .gui-file:hover + .gui-input,
.allcp-form.theme-info .gui-textarea:hover ~ .input-footer {
  border-color: #4fc1e9;
}
.allcp-form.theme-info .gui-input:focus,
.allcp-form.theme-info .gui-textarea:focus,
.allcp-form.theme-info .select > select:focus,
.allcp-form.theme-info .select-multiple select:focus {
  border-color: #4fc1e9;
  color: #3C3C3C;
}
.allcp-form.theme-info .gui-textarea:focus {
  height: 120px;
}
.allcp-form.theme-info .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form.theme-info .gui-input:focus ~ .field-icon i,
.allcp-form.theme-info .gui-textarea:focus ~ .field-icon i {
  color: #4fd8b0;
}
.allcp-form.theme-info .select-multiple select:focus,
.allcp-form.theme-info .gui-input:focus ~ .input-footer,
.allcp-form.theme-info .gui-textarea:focus ~ .input-footer,
.allcp-form.theme-info .file .gui-file:focus + .gui-input {
  border-color: #4fc1e9;
}
.allcp-form.theme-info .select > select:focus + .arrow {
  color: #4fd8b0;
}
.allcp-form.theme-info .ui-datepicker .ui-datepicker-prev,
.allcp-form.theme-info .ui-datepicker .ui-datepicker-next,
.allcp-form.theme-info .ui-datepicker .ui-datepicker-title {
  color: #4fd8b0;
}
.allcp-form.theme-info .ui-datepicker-today a,
.allcp-form.theme-info .ui-datepicker-calendar a:hover,
.allcp-form.theme-info .ui-datepicker-today a:hover,
.allcp-form.theme-info .ui-datepicker .ui-state-highlight {
  background-color: #ffffff !important;
  color: #4fd8b0 !important;
}
.allcp-form.theme-info .ui-datepicker .ui-state-active {
  background-color: #ffffff !important;
  color: #4fd8b0 !important;
}
.allcp-form.theme-info .rating:hover .rating-star:hover,
.allcp-form.theme-info .rating:hover .rating-star:hover ~ .rating-star,
.allcp-form.theme-info .rating-input:checked ~ .rating-star {
  color: #469aa7;
}
.allcp-form.theme-info .rating-star,
.allcp-form.theme-info .rating:hover .rating-star {
  color: #b9bbc5;
}
.allcp-form.theme-info .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #FFF !important;
}
.allcp-form.theme-info .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #4fd8b0;
}
.allcp-form.theme-info .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #4fd8b0;
}
.allcp-form.theme-info .wizard .steps li a,
.allcp-form.theme-info .wizard .actions li a {
  background-color: #4fd8b0;
}
.allcp-form.theme-info .wizard .steps li.current a {
  background-color: #27a0cc;
}
.allcp-form .bg-primary {
  background-color: #469aa7;
}
.allcp-form .bg-primary.light {
  background-color: #469aa7;
}
.allcp-form .bg-primary.lighter {
  background-color: #83aee7;
}
.allcp-form .bg-primary.dark {
  background-color: #2489cc;
}
.allcp-form .bg-primary.darker {
  background-color: #2567bd;
}
.allcp-form .panel-primary > .panel-heading {
  border-top-color: #469aa7;
}
.allcp-form .panel-primary > .panel-heading:before {
  background-color: #469aa7;
}
.allcp-form .panel-primary.heading-border:before,
.allcp-form .panel-primary .heading-border:before {
  background-color: #469aa7;
}
.allcp-form .option-primary input:hover + .checkbox,
.allcp-form .option-primary input:hover + .radio {
  border-color: #469aa7;
}
.allcp-form .option-primary input:checked + .checkbox,
.allcp-form .option-primary input:focus + .checkbox,
.allcp-form .option-primary input:checked + .radio,
.allcp-form .option-primary input:focus + .radio {
  border-color: #469aa7;
}
.allcp-form .option-primary input:checked + .radio:before,
.allcp-form .option-primary input:focus + .radio:before {
  background: #469aa7;
}
.allcp-form .option-primary input:checked + .checkbox:before,
.allcp-form .option-primary input:focus + .checkbox:before {
  border-color: #469aa7;
}

.allcp-form .switch-primary > input:checked + label {
  background: transparent;
  border-color: #469aa7;
  color: #469aa7;
}
.allcp-form .switch-primary > input:checked + label:after {
  background: #469aa7;
  color: #469aa7;
}
.allcp-form .switch-primary > input:checked:focus + label {
  background: #fff;
  border-color: #469aa7;
}
.allcp-form .slider-wrapper.slider-primary .ui-slider .ui-slider-range {
  background-color: #469aa7;
}
.allcp-form .slider-wrapper.slider-primary .ui-slider .ui-slider-handle {
  border-color: #469aa7;
}
.allcp-form .slider-wrapper.slider-primary .ui-slider .ui-slider-handle:before {
  background-color: #469aa7;
}
.allcp-form.theme-primary .tagline span {
  color: #469aa7;
}
.allcp-form.theme-primary .theme-link {
  color: #469aa7;
}
.allcp-form.theme-primary .section-divider span {
  color: #469aa7;
}
.allcp-form.theme-primary .gui-input:hover,
.allcp-form.theme-primary .gui-textarea:hover,
.allcp-form.theme-primary .select > select:hover,
.allcp-form.theme-primary .select-multiple select:hover,
.allcp-form.theme-primary .gui-input:hover ~ .input-footer,
.allcp-form.theme-primary .file .gui-file:hover + .gui-input,
.allcp-form.theme-primary .gui-textarea:hover ~ .input-footer {
  border-color: #469aa7;
}
.allcp-form.theme-primary .gui-input:focus,
.allcp-form.theme-primary .gui-textarea:focus,
.allcp-form.theme-primary .select > select:focus,
.allcp-form.theme-primary .select-multiple select:focus {
  border-color: #469aa7;
  color: #3C3C3C;
}
.allcp-form.theme-primary .gui-textarea:focus {
  height: 120px;
}
.allcp-form.theme-primary .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form.theme-primary .gui-input:focus ~ .field-icon i,
.allcp-form.theme-primary .gui-textarea:focus ~ .field-icon i {
  color: #469aa7;
}
.allcp-form.theme-primary .select-multiple select:focus,
.allcp-form.theme-primary .gui-input:focus ~ .input-footer,
.allcp-form.theme-primary .gui-textarea:focus ~ .input-footer,
.allcp-form.theme-primary .file .gui-file:focus + .gui-input {
  border-color: #469aa7;
}
.allcp-form.theme-primary .select > select:focus + .arrow {
  color: #469aa7;
}
.allcp-form.theme-primary .ui-datepicker .ui-datepicker-prev,
.allcp-form.theme-primary .ui-datepicker .ui-datepicker-next,
.allcp-form.theme-primary .ui-datepicker .ui-datepicker-title {
  color: #469aa7;
}
.allcp-form.theme-primary .ui-datepicker-today a,
.allcp-form.theme-primary .ui-datepicker-calendar a:hover,
.allcp-form.theme-primary .ui-datepicker-today a:hover,
.allcp-form.theme-primary .ui-datepicker .ui-state-highlight {
  background-color: #ffffff !important;
  color: #469aa7 !important;
}
.allcp-form.theme-primary .ui-datepicker .ui-state-active {
  background-color: #ffffff !important;
  color: #469aa7 !important;
}
.allcp-form.theme-primary .rating:hover .rating-star:hover,
.allcp-form.theme-primary .rating:hover .rating-star:hover ~ .rating-star,
.allcp-form.theme-primary .rating-input:checked ~ .rating-star {
  color: #469aa7;
}
.allcp-form.theme-primary .rating-star,
.allcp-form.theme-primary .rating:hover .rating-star {
  color: #b9bbc5;
}
.allcp-form.theme-primary .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #FFF !important;
}
.allcp-form.theme-primary .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #469aa7;
}
.allcp-form.theme-primary .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #469aa7;
}
.allcp-form.theme-primary .wizard .steps li a,
.allcp-form.theme-primary .wizard .actions li a {
  background-color: #469aa7;
}
.allcp-form.theme-primary .wizard .steps li.current a {
  background-color: #2489cc;
}
.allcp-form .bg-success {
  background-color: #c3d62d;
}
.allcp-form .bg-success.light {
  background-color: #87d37c;
}
.allcp-form .bg-success.lighter {
  background-color: #9edc95;
}
.allcp-form .bg-success.dark {
  background-color: #58c249;
}
.allcp-form .bg-success.darker {
  background-color: #49ae3b;
}
.allcp-form .panel-success > .panel-heading {
  border-top-color: #87d37c;
}
.allcp-form .panel-success > .panel-heading:before {
  background-color: #c3d62d;
}
.allcp-form .panel-success.heading-border:before,
.allcp-form .panel-success .heading-border:before {
  background-color: #c3d62d;
}
.allcp-form .option-success input:hover + .checkbox,
.allcp-form .option-success input:hover + .radio {
  border-color: #87d37c;
}
.allcp-form .option-success input:checked + .checkbox,
.allcp-form .option-success input:focus + .checkbox,
.allcp-form .option-success input:checked + .radio,
.allcp-form .option-success input:focus + .radio {
  border-color: #c3d62d;
}
.allcp-form .option-success input:checked + .radio:before,
.allcp-form .option-success input:focus + .radio:before {
  background: #c3d62d;
}
.allcp-form .option-success input:checked + .checkbox:before,
.allcp-form .option-success input:focus + .checkbox:before {
  border-color: #c3d62d;
}
.allcp-form .btn-success {
  background-color: #469aa7;
}
.allcp-form .btn-success:hover,
.allcp-form .btn-success:focus {
  background-color: #6ea9ad;
  border-color: #6ea9ad;
}
.allcp-form .btn-success:active {
  background-color: #58c249;
}
.allcp-form .btn-success,
.allcp-form .btn-success:hover,
.allcp-form .btn-success:focus,
.allcp-form .btn-success:active {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}
.allcp-form .switch-success > input:checked + label {
  background: transparent;
  border-color: #c3d62d;
  color: #c3d62d;
}
.allcp-form .switch-success > input:checked + label:after {
  background: #c3d62d;
  color: #c3d62d;
}
.allcp-form .switch-success > input:checked:focus + label {
  background: #fff;
  border-color: #c3d62d;
}
.allcp-form .slider-wrapper.slider-success .ui-slider .ui-slider-range {
  background-color: #c3d62d;
}
.allcp-form .slider-wrapper.slider-success .ui-slider .ui-slider-handle {
  border-color: #c3d62d;
}
.allcp-form .slider-wrapper.slider-success .ui-slider .ui-slider-handle:before {
  background-color: #c3d62d;
}
.allcp-form.theme-success .tagline span {
  color: #c3d62d;
}
.allcp-form.theme-success .theme-link {
  color: #c3d62d;
}
.allcp-form.theme-success .section-divider span {
  color: #c3d62d;
}
.allcp-form.theme-success .gui-input:hover,
.allcp-form.theme-success .gui-textarea:hover,
.allcp-form.theme-success .select > select:hover,
.allcp-form.theme-success .select-multiple select:hover,
.allcp-form.theme-success .gui-input:hover ~ .input-footer,
.allcp-form.theme-success .file .gui-file:hover + .gui-input,
.allcp-form.theme-success .gui-textarea:hover ~ .input-footer {
  border-color: #87d37c;
}
.allcp-form.theme-success .gui-input:focus,
.allcp-form.theme-success .gui-textarea:focus,
.allcp-form.theme-success .select > select:focus,
.allcp-form.theme-success .select-multiple select:focus {
  border-color: #87d37c;
  color: #3C3C3C;
}
.allcp-form.theme-success .gui-textarea:focus {
  height: 120px;
}
.allcp-form.theme-success .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form.theme-success .gui-input:focus ~ .field-icon i,
.allcp-form.theme-success .gui-textarea:focus ~ .field-icon i {
  color: #c3d62d;
}
.allcp-form.theme-success .select-multiple select:focus,
.allcp-form.theme-success .gui-input:focus ~ .input-footer,
.allcp-form.theme-success .gui-textarea:focus ~ .input-footer,
.allcp-form.theme-success .file .gui-file:focus + .gui-input {
  border-color: #87d37c;
}
.allcp-form.theme-success .select > select:focus + .arrow {
  color: #c3d62d;
}
.allcp-form.theme-success .ui-datepicker .ui-datepicker-prev,
.allcp-form.theme-success .ui-datepicker .ui-datepicker-next,
.allcp-form.theme-success .ui-datepicker .ui-datepicker-title {
  color: #c3d62d;
}
.allcp-form.theme-success .ui-datepicker-today a,
.allcp-form.theme-success .ui-datepicker-calendar a:hover,
.allcp-form.theme-success .ui-datepicker-today a:hover,
.allcp-form.theme-success .ui-datepicker .ui-state-highlight {
  background-color: #ffffff !important;
  color: #c3d62d !important;
}
.allcp-form.theme-success .ui-datepicker .ui-state-active {
  background-color: #ffffff !important;
  color: #c3d62d !important;
}
.allcp-form.theme-success .rating:hover .rating-star:hover,
.allcp-form.theme-success .rating:hover .rating-star:hover ~ .rating-star,
.allcp-form.theme-success .rating-input:checked ~ .rating-star {
  color: #469aa7;
}
.allcp-form.theme-success .rating-star,
.allcp-form.theme-success .rating:hover .rating-star {
  color: #b9bbc5;
}
.allcp-form.theme-success .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #FFF !important;
}
.allcp-form.theme-success .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #c3d62d;
}
.allcp-form.theme-success .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #c3d62d;
}
.allcp-form.theme-success .wizard .steps li a,
.allcp-form.theme-success .wizard .actions li a {
  background-color: #c3d62d;
}
.allcp-form.theme-success .wizard .steps li.current a {
  background-color: #58c249;
}
.allcp-form .bg-system.light {
  background-color: #48cfad;
}
.allcp-form .bg-system.lighter {
  background-color: #65d2b7;
}
.allcp-form .bg-system.dark {
  background-color: #2fa285;
}
.allcp-form .bg-system.darker {
  background-color: #288770;
}
.allcp-form .panel-system > .panel-heading {
  border-top-color: #48cfad;
}
.allcp-form .panel-system > .panel-heading:before {
  background-color: #5a5386;
}
.allcp-form .panel-system.heading-border:before,
.allcp-form .panel-system .heading-border:before {
  background-color: #5a5386;
}
.allcp-form .option-system input:hover + .checkbox,
.allcp-form .option-system input:hover + .radio {
  border-color: #48cfad;
}
.allcp-form .option-system input:checked + .checkbox,
.allcp-form .option-system input:focus + .checkbox,
.allcp-form .option-system input:checked + .radio,
.allcp-form .option-system input:focus + .radio {
  border-color: #5a5386;
}
.allcp-form .option-system input:checked + .radio:before,
.allcp-form .option-system input:focus + .radio:before {
  background: #5a5386;
}
.allcp-form .option-system input:checked + .checkbox:before,
.allcp-form .option-system input:focus + .checkbox:before {
  border-color: #5a5386;
}
.allcp-form .btn-system {
  background-color: #5a5386;
}
.allcp-form .btn-system:hover,
.allcp-form .btn-system:focus {
  background-color: #48cfad;
  border-color: #48cfad;
}
.allcp-form .btn-system:active {
  background-color: #2fa285;
}
.allcp-form .btn-system,
.allcp-form .btn-system:hover,
.allcp-form .btn-system:focus,
.allcp-form .btn-system:active {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}
.allcp-form .switch-system > input:checked + label {
  background: transparent;
  border-color: #5a5386;
  color: #5a5386;
}
.allcp-form .switch-system > input:checked + label:after {
  background: #5a5386;
  color: #5a5386;
}
.allcp-form .switch-system > input:checked:focus + label {
  background: #fff;
  border-color: #5a5386;
}
.allcp-form .slider-wrapper.slider-system .ui-slider .ui-slider-range {
  background-color: #5a5386;
}
.allcp-form .slider-wrapper.slider-system .ui-slider .ui-slider-handle {
  border-color: #5a5386;
}
.allcp-form .slider-wrapper.slider-system .ui-slider .ui-slider-handle:before {
  background-color: #5a5386;
}
.allcp-form.theme-system .tagline span {
  color: #5a5386;
}
.allcp-form.theme-system .theme-link {
  color: #5a5386;
}
.allcp-form.theme-system .section-divider span {
  color: #5a5386;
}
.allcp-form.theme-system .gui-input:hover,
.allcp-form.theme-system .gui-textarea:hover,
.allcp-form.theme-system .select > select:hover,
.allcp-form.theme-system .select-multiple select:hover,
.allcp-form.theme-system .gui-input:hover ~ .input-footer,
.allcp-form.theme-system .file .gui-file:hover + .gui-input,
.allcp-form.theme-system .gui-textarea:hover ~ .input-footer {
  border-color: #48cfad;
}
.allcp-form.theme-system .gui-input:focus,
.allcp-form.theme-system .gui-textarea:focus,
.allcp-form.theme-system .select > select:focus,
.allcp-form.theme-system .select-multiple select:focus {
  border-color: #48cfad;
  color: #3C3C3C;
}
.allcp-form.theme-system .gui-textarea:focus {
  height: 120px;
}
.allcp-form.theme-system .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form.theme-system .gui-input:focus ~ .field-icon i,
.allcp-form.theme-system .gui-textarea:focus ~ .field-icon i {
  color: #5a5386;
}
.allcp-form.theme-system .select-multiple select:focus,
.allcp-form.theme-system .gui-input:focus ~ .input-footer,
.allcp-form.theme-system .gui-textarea:focus ~ .input-footer,
.allcp-form.theme-system .file .gui-file:focus + .gui-input {
  border-color: #48cfad;
}
.allcp-form.theme-system .select > select:focus + .arrow {
  color: #5a5386;
}
.allcp-form.theme-system .ui-datepicker .ui-datepicker-prev,
.allcp-form.theme-system .ui-datepicker .ui-datepicker-next,
.allcp-form.theme-system .ui-datepicker .ui-datepicker-title {
  color: #5a5386;
}
.allcp-form.theme-system .ui-datepicker-today a,
.allcp-form.theme-system .ui-datepicker-calendar a:hover,
.allcp-form.theme-system .ui-datepicker-today a:hover,
.allcp-form.theme-system .ui-datepicker .ui-state-highlight {
  background-color: #ffffff !important;
  color: #5a5386 !important;
}
.allcp-form.theme-system .ui-datepicker .ui-state-active {
  background-color: #ffffff !important;
  color: #5a5386 !important;
}
.allcp-form.theme-system .rating:hover .rating-star:hover,
.allcp-form.theme-system .rating:hover .rating-star:hover ~ .rating-star,
.allcp-form.theme-system .rating-input:checked ~ .rating-star {
  color: #469aa7;
}
.allcp-form.theme-system .rating-star,
.allcp-form.theme-system .rating:hover .rating-star {
  color: #b9bbc5;
}
.allcp-form.theme-system .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #FFF !important;
}
.allcp-form.theme-system .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #5a5386;
}
.allcp-form.theme-system .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #5a5386;
}
.allcp-form.theme-system .wizard .steps li a,
.allcp-form.theme-system .wizard .actions li a {
  background-color: #5a5386;
}
.allcp-form.theme-system .wizard .steps li.current a {
  background-color: #2fa285;
}
.allcp-form .bg-warning {
  background-color: #d4460f;
}
.allcp-form .bg-warning.light {
  background-color: #ffce54;
}
.allcp-form .bg-warning.lighter {
  background-color: #f9d283;
}
.allcp-form .bg-warning.dark {
  background-color: #f4af22;
}
.allcp-form .bg-warning.darker {
  background-color: #d9950a;
}
.allcp-form .panel-warning > .panel-heading {
  border-top-color: #ffce54;
}
.allcp-form .panel-warning > .panel-heading:before {
  background-color: #d4460f;
}
.allcp-form .panel-warning.heading-border:before,
.allcp-form .panel-warning .heading-border:before {
  background-color: #d4460f;
}
.allcp-form .option-warning input:hover + .checkbox,
.allcp-form .option-warning input:hover + .radio {
  border-color: #ffce54;
}
.allcp-form .option-warning input:checked + .checkbox,
.allcp-form .option-warning input:focus + .checkbox,
.allcp-form .option-warning input:checked + .radio,
.allcp-form .option-warning input:focus + .radio {
  border-color: #d4460f;
}
.allcp-form .option-warning input:checked + .radio:before,
.allcp-form .option-warning input:focus + .radio:before {
  background: #d4460f;
}
.allcp-form .option-warning input:checked + .checkbox:before,
.allcp-form .option-warning input:focus + .checkbox:before {
  border-color: #d4460f;
}
.allcp-form .btn-warning {
  background-color: #f1703f;
}
.allcp-form .btn-warning:hover,
.allcp-form .btn-warning:focus {
  background-color: #d4460f !important;
  border-color: #d4460f !important;
}
.allcp-form .btn-warning:active {
  background-color: #f4af22;
}
.allcp-form .btn-warning,
.allcp-form .btn-warning:hover,
.allcp-form .btn-warning:focus,
.allcp-form .btn-warning:active {
  color: #fff;
  text-shadow: none !important;
}
.allcp-form .switch-warning > input:checked + label {
  background: transparent;
  border-color: #d4460f;
  color: #d4460f;
}
.allcp-form .switch-warning > input:checked + label:after {
  background: #d4460f;
  color: #d4460f;
}
.allcp-form .switch-warning > input:checked:focus + label {
  background: #fff;
  border-color: #d4460f;
}
.allcp-form .slider-wrapper.slider-warning .ui-slider .ui-slider-range {
  background-color: #d4460f;
}
.allcp-form .slider-wrapper.slider-warning .ui-slider .ui-slider-handle {
  border-color: #d4460f;
}
.allcp-form .slider-wrapper.slider-warning .ui-slider .ui-slider-handle:before {
  background-color: #d4460f;
}
.allcp-form.theme-warning .tagline span {
  color: #d4460f;
}
.allcp-form.theme-warning .theme-link {
  color: #d4460f;
}
.allcp-form.theme-warning .section-divider span {
  color: #d4460f;
}
.allcp-form.theme-warning .gui-input:hover,
.allcp-form.theme-warning .gui-textarea:hover,
.allcp-form.theme-warning .select > select:hover,
.allcp-form.theme-warning .select-multiple select:hover,
.allcp-form.theme-warning .gui-input:hover ~ .input-footer,
.allcp-form.theme-warning .file .gui-file:hover + .gui-input,
.allcp-form.theme-warning .gui-textarea:hover ~ .input-footer {
  border-color: #ffce54;
}
.allcp-form.theme-warning .gui-input:focus,
.allcp-form.theme-warning .gui-textarea:focus,
.allcp-form.theme-warning .select > select:focus,
.allcp-form.theme-warning .select-multiple select:focus {
  border-color: #ffce54;
  color: #3C3C3C;
}
.allcp-form.theme-warning .gui-textarea:focus {
  height: 120px;
}
.allcp-form.theme-warning .select > select:focus {
  z-index: 10;
  z-index: 20\9;
}
.allcp-form.theme-warning .gui-input:focus ~ .field-icon i,
.allcp-form.theme-warning .gui-textarea:focus ~ .field-icon i {
  color: #d4460f;
}
.allcp-form.theme-warning .select-multiple select:focus,
.allcp-form.theme-warning .gui-input:focus ~ .input-footer,
.allcp-form.theme-warning .gui-textarea:focus ~ .input-footer,
.allcp-form.theme-warning .file .gui-file:focus + .gui-input {
  border-color: #ffce54;
}
.allcp-form.theme-warning .select > select:focus + .arrow {
  color: #d4460f;
}
.allcp-form.theme-warning .ui-datepicker .ui-datepicker-prev,
.allcp-form.theme-warning .ui-datepicker .ui-datepicker-next,
.allcp-form.theme-warning .ui-datepicker .ui-datepicker-title {
  color: #d4460f;
}
.allcp-form.theme-warning .ui-datepicker-today a,
.allcp-form.theme-warning .ui-datepicker-calendar a:hover,
.allcp-form.theme-warning .ui-datepicker-today a:hover,
.allcp-form.theme-warning .ui-datepicker .ui-state-highlight {
  background-color: #ffffff !important;
  color: #d4460f !important;
}
.allcp-form.theme-warning .ui-datepicker .ui-state-active {
  background-color: #ffffff !important;
  color: #d4460f !important;
}
.allcp-form.theme-warning .rating:hover .rating-star:hover,
.allcp-form.theme-warning .rating:hover .rating-star:hover ~ .rating-star,
.allcp-form.theme-warning .rating-input:checked ~ .rating-star {
  color: #469aa7;
}
.allcp-form.theme-warning .rating-star,
.allcp-form.theme-warning .rating:hover .rating-star {
  color: #b9bbc5;
}
.allcp-form.theme-warning .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #FFF !important;
}
.allcp-form.theme-warning .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #d4460f;
}
.allcp-form.theme-warning .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #d4460f;
}
.allcp-form.theme-warning .wizard .steps li a,
.allcp-form.theme-warning .wizard .actions li a {
  background-color: #d4460f;
}
.allcp-form.theme-warning .wizard .steps li.current a {
  background-color: #f4af22;
}
/*# sourceMappingURL=../css/forms.map */
