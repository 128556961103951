@-webkit-keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes ui-spinner-rotate-right {
  0% {
    -moz-transform: rotate(0deg);
  }
  25% {
    -moz-transform: rotate(180deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  75% {
    -moz-transform: rotate(360deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-moz-keyframes ui-spinner-rotate-left {
  0% {
    -moz-transform: rotate(0deg);
  }
  25% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  75% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes ui-spinner-rotate-right {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ui-spinner-rotate-left {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* line 118, ../../src/sass/messenger-spinner.scss */
.messenger-spinner {
  position: relative;
  border-radius: 100%;
}
/* line 122, ../../src/sass/messenger-spinner.scss */
ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
  display: block;
}
/* line 126, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
/* line 132, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  border-radius: 999px;
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
/* line 142, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left {
  left: 0;
}
/* line 145, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-animation-name: ui-spinner-rotate-left;
  -webkit-animation-name: ui-spinner-rotate-left;
  animation-name: ui-spinner-rotate-left;
  -moz-transform-origin: 0 50% 50%;
  -ms-transform-origin: 0 50% 50%;
  -webkit-transform-origin: 0 50% 50%;
  transform-origin: 0 50% 50%;
}
/* line 154, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right {
  left: 50%;
}
/* line 157, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
  left: -100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-animation-name: ui-spinner-rotate-right;
  -webkit-animation-name: ui-spinner-rotate-right;
  animation-name: ui-spinner-rotate-right;
  -moz-transform-origin: 100% 50% 50%;
  -ms-transform-origin: 100% 50% 50%;
  -webkit-transform-origin: 100% 50% 50%;
  transform-origin: 100% 50% 50%;
}

/* line 15, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future {
  -moz-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.24), 0px 1px 5px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.24), 0px 1px 5px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 1px rgba(255, 255, 255, 0.24), 0px 1px 5px rgba(0, 0, 0, 0.6);
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzVjNWI1YiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzM1MzUzNSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #5c5b5b), color-stop(100%, #353535));
  background-image: -moz-linear-gradient(#5c5b5b, #353535);
  background-image: -webkit-linear-gradient(#5c5b5b, #353535);
  background-image: linear-gradient(#5c5b5b, #353535);
  background-color: #5c5b5b;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
/* line 23, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message {
  -moz-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 0px -1px rgba(0, 0, 0, 0.23), inset 48px 0px 0px rgba(0, 0, 0, 0.3), inset 46px 0px 0px rgba(255, 255, 255, 0.07);
  -webkit-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 0px -1px rgba(0, 0, 0, 0.23), inset 48px 0px 0px rgba(0, 0, 0, 0.3), inset 46px 0px 0px rgba(255, 255, 255, 0.07);
  box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 0px -1px rgba(0, 0, 0, 0.23), inset 48px 0px 0px rgba(0, 0, 0, 0.3), inset 46px 0px 0px rgba(255, 255, 255, 0.07);
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  position: relative;
  border: 0px;
  margin-bottom: 0px;
  font-size: 13px;
  background: transparent;
  color: #f0f0f0;
  text-shadow: 0px 1px #111;
  font-weight: 500;
  padding: 10px 30px 13px 65px;
}
/* line 36, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message a {
  color: #5599ff;
}
/* line 39, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message .messenger-close {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #888;
  text-shadow: 0px 1px #000;
  opacity: 1;
  font-weight: bold;
  display: block;
  font-size: 20px;
  line-height: 20px;
  padding: 8px 10px 7px 7px;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
/* line 56, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message .messenger-close:hover {
  color: #bbb;
}
/* line 59, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message .messenger-close:active {
  color: #777;
}
/* line 62, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message .messenger-actions {
  float: none;
  margin-top: 10px;
}
/* line 66, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message .messenger-actions a {
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2), inset 0px 1px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2), inset 0px 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2), inset 0px 1px rgba(255, 255, 255, 0.1);
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  color: #aaa;
  text-shadow: 0px 1px #222;
  margin-right: 10px;
  padding: 3px 10px 5px;
  text-transform: capitalize;
}
/* line 78, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message .messenger-actions a:hover {
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2), inset 0px 1px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2), inset 0px 1px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2), inset 0px 1px rgba(255, 255, 255, 0.2);
  color: #f0f0f0;
}
/* line 82, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message .messenger-actions a:active {
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.28), inset 0px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.28), inset 0px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.28), inset 0px 1px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.04);
  color: #aaa;
}
/* line 87, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message .messenger-actions .messenger-phrase {
  display: none;
}
/* line 90, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message .messenger-message-inner:before {
  -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.6), 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.6), 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.6), 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 17px;
  display: block;
  content: " ";
  top: 50%;
  margin-top: -8px;
  height: 13px;
  width: 13px;
  z-index: 20;
}
/* line 103, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message.alert-success .messenger-message-inner:before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzVmY2E0YSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzA5OGQzOCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #5fca4a), color-stop(100%, #098d38));
  background-image: -moz-linear-gradient(top, #5fca4a, #098d38);
  background-image: -webkit-linear-gradient(top, #5fca4a, #098d38);
  background-image: linear-gradient(to bottom, #5fca4a, #098d38);
  background-color: #5fca4a;
}
/* line 107, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message.alert-info .messenger-message-inner:before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzYxYzRiOCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzE5OTJhMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #61c4b8), color-stop(100%, #1992a3));
  background-image: -moz-linear-gradient(top, #61c4b8, #1992a3);
  background-image: -webkit-linear-gradient(top, #61c4b8, #1992a3);
  background-image: linear-gradient(to bottom, #61c4b8, #1992a3);
  background-color: #61c4b8;
}
/* line 113, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message.alert-error .messenger-message-inner:before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RkNmE0NSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzkxMzYxYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #dd6a45), color-stop(100%, #91361a));
  background-image: -moz-linear-gradient(top, #dd6a45, #91361a);
  background-image: -webkit-linear-gradient(top, #dd6a45, #91361a);
  background-image: linear-gradient(to bottom, #dd6a45, #91361a);
  background-color: #dd6a45;
}
/* line 34, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-future .messenger-message.alert-error.messenger-retry-soon .messenger-spinner {
  width: 32px;
  height: 32px;
  background: transparent;
}
/* line 39, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-future .messenger-message.alert-error.messenger-retry-soon .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  background: #dd6a45;
  -moz-animation-duration: 20s;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  opacity: 1;
}
/* line 47, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-future .messenger-message.alert-error.messenger-retry-soon .messenger-spinner:after {
  content: "";
  background: #333;
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  display: block;
}
/* line 34, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-future .messenger-message.alert-error.messenger-retry-later .messenger-spinner {
  width: 32px;
  height: 32px;
  background: transparent;
}
/* line 39, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-future .messenger-message.alert-error.messenger-retry-later .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  background: #dd6a45;
  -moz-animation-duration: 600s;
  -webkit-animation-duration: 600s;
  animation-duration: 600s;
  opacity: 1;
}
/* line 47, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-future .messenger-message.alert-error.messenger-retry-later .messenger-spinner:after {
  content: "";
  background: #333;
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  display: block;
}
/* line 125, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message-slot.messenger-last .messenger-message {
  -moz-border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px;
  border-radius: 4px 4px 0px 0px;
}
/* line 128, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message-slot.messenger-first .messenger-message {
  -moz-border-radius: 0px 0px 4px 4px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 4px 4px;
  -moz-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px rgba(0, 0, 0, 0.3), inset 46px 0px 0px rgba(255, 255, 255, 0.07);
  -webkit-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px rgba(0, 0, 0, 0.3), inset 46px 0px 0px rgba(255, 255, 255, 0.07);
  box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px rgba(0, 0, 0, 0.3), inset 46px 0px 0px rgba(255, 255, 255, 0.07);
}
/* line 132, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-message-slot.messenger-first.messenger-last .messenger-message {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: inset 48px 0px 0px rgba(0, 0, 0, 0.3), inset 46px 0px 0px rgba(255, 255, 255, 0.07);
  -webkit-box-shadow: inset 48px 0px 0px rgba(0, 0, 0, 0.3), inset 46px 0px 0px rgba(255, 255, 255, 0.07);
  box-shadow: inset 48px 0px 0px rgba(0, 0, 0, 0.3), inset 46px 0px 0px rgba(255, 255, 255, 0.07);
}
/* line 136, ../../src/sass/messenger-theme-future.sass */
ul.messenger-theme-future .messenger-spinner {
  display: block;
  position: absolute;
  left: 7px;
  top: 50%;
  margin-top: -18px;
  z-index: 999;
  height: 32px;
  width: 32px;
  z-index: 10;
}
